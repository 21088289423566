import { generateText } from '@tiptap/core';
import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import TextStyle from '@tiptap/extension-text-style';
import Youtube from '@tiptap/extension-youtube';
import { generateHTML } from '@tiptap/html';
import StarterKit from '@tiptap/starter-kit';
import { getProperty } from './AssignObjectKeysHelper';
import { CustomLink } from 'components/Editors/RichTextEditor/extensions/CustomLink';
import { Callout } from 'services/TipTapCallout';
import Iframe from 'services/Iframe';
import { HelpCenterArticleExtension } from 'components/Editors/RichTextEditor/extensions/HelpcenterExtension';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { uploadFileToServer } from 'services/FileUpload';
import Placeholder from '@tiptap/extension-placeholder';
import { sanitizeHtmlHelper } from './SanitizeHtmlHelper';
import _ from 'lodash';

export const convertTipTapToPlainText = ({
  content: originalContent,
  variables = {},
}) => {
  try {
    let content = _.cloneDeep(originalContent);
    // Replace server variables with actual values.
    content = replaceServerVariables({ contentJson: content, variables });

    // It's already a string.
    if (content && (typeof content === 'string' || content instanceof String)) {
      return content as string;
    }

    // Convert to plain text.
    const plainText = generateText(content, [
      StarterKit.configure({ heading: false }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      Mention,
      CustomLink,
      Image,
      Iframe,
      Placeholder,
      Callout,
      TextStyle,
      HelpCenterArticleExtension,
      Youtube.configure(),
    ]);

    return plainText;
  } catch (exp) {
    console.error(exp);
    return '';
  }
};

function replaceCodeBlocks(content) {
  try {
    if (content && (typeof content === 'string' || content instanceof String)) {
      const codeBlockRegex = /```([^`]+)```/g;
      content = content.replace(codeBlockRegex, (match, p1) => {
        let codeContent = p1.trim();
        if (codeContent.startsWith('<br />')) {
          codeContent = codeContent.replace('<br />', '');
        }
        return `<pre><code>${codeContent}</code></pre>`;
      });
      return sanitizeHtmlHelper(content);
    }
    return content;
  } catch (error) {
    return sanitizeHtmlHelper(content);
  }
}

export const convertTipTapToHtml = ({
  content: originalContent,
  variables = {},
  fallbackValue = "Content can't be displayed",
}) => {
  try {
    let content = _.cloneDeep(originalContent);

    // It's a string.
    if (content && (typeof content === 'string' || content instanceof String)) {
      if (content.includes('```')) {
        return replaceCodeBlocks(content);
      }
      return sanitizeHtmlHelper(content as string);
    }

    // Replace server variables with actual values
    content = replaceServerVariables({ contentJson: content, variables });

    let html = generateHTML(content, [
      StarterKit.configure({ heading: false }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      Mention,
      CustomLink,
      Image,
      Iframe,
      Callout,
      TextStyle,
      HelpCenterArticleExtension,
      Youtube.configure(),
    ]);
    let regex = /<helpcenterarticle.*?>.*?<\/helpcenterarticle>/gs;
    let matches = Array.from(html.matchAll(regex));

    for (let i = 0; i < matches.length; i++) {
      let match = matches[i];
      let textToReplace = match[0];

      // perform the necessary replacements on textToReplace
      let replacedText = replaceAll(textToReplace, '&lt;', '<');
      replacedText = replaceAll(replacedText, '&gt;', '>');
      replacedText = replaceAll(replacedText, '&quot;', '"');
      replacedText = replaceAll(replacedText, 'helpcenterarticle', 'div');
      // replace the original text with the modified text
      html = html.replace(textToReplace, replacedText);
    }

    html = replaceAll(html, '\n', '<br>');

    return html;
  } catch (exp) {
    console.error(exp);
    return `<p>${fallbackValue}</p>`;
  }
};

export const replaceServerVariables = ({
  contentJson: originalJsonContent,
  variables = {},
}) => {
  try {
    if (!originalJsonContent || typeof originalJsonContent !== 'object') {
      return originalJsonContent;
    }

    let contentJson = _.cloneDeep(originalJsonContent);

    // Check if the current node is a serverVariable node
    if (contentJson.type === 'serverVariable' && contentJson.attrs) {
      const variableName = contentJson.attrs?.variableName;
      const fallbackValue = contentJson.attrs?.fallbackValue ?? '';

      // This is a special case for the session.firstName variable.
      if (variableName === 'session.firstName') {
        const actualValue = (
          getProperty(variables, 'session.name') || fallbackValue
        ).split(' ')[0];
        return {
          type: 'text',
          text: actualValue,
        };
      } else {
        const actualValue =
          getProperty(variables, variableName) || fallbackValue;
        return {
          type: 'text',
          text: actualValue,
        };
      }
    }

    // Function to replace variables in a string
    const replaceVariablesInString = (string) => {
      return string.replace(/\{\{(\w+(\.\w+)*)\}\}/g, (match, variableName) => {
        return getProperty(variables, variableName) || '';
      });
    };

    // Check if the node is a link and has an href attribute
    if (
      contentJson.type === 'link' &&
      contentJson.attrs &&
      contentJson.attrs.href
    ) {
      contentJson.attrs.href = replaceVariablesInString(contentJson.attrs.href);
    }

    // Process link marks within text nodes
    if (contentJson.type === 'text' && contentJson.marks) {
      contentJson.marks.forEach((mark) => {
        if (mark.type === 'link' && mark.attrs && mark.attrs.href) {
          mark.attrs.href = replaceVariablesInString(mark.attrs.href);
        }
      });
    }

    // Recursively process nested content items
    if (contentJson.content && Array.isArray(contentJson.content)) {
      return {
        ...contentJson,
        content: contentJson.content.map((node) =>
          replaceServerVariables({ contentJson: node, variables }),
        ),
      };
    }

    return contentJson;
  } catch (_) {
    return originalJsonContent;
  }
};

const escapeRegExp = function (string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const replaceAll = function (
  str: string | String,
  match: string,
  replacement: string,
) {
  return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
};

export const imageRenderHelper = (images) => {
  if (!images || images.length === 0) {
    return;
  }

  images.forEach((img) => {
    img.onload = () => {
      const naturalWidth = img.naturalWidth;
      const naturalHeight = img.naturalHeight;
      const renderedWidth = img.width;
      const renderedHeight = img.height;

      const isBlank = naturalWidth <= 1 && naturalHeight <= 1;

      if (isBlank) {
        img.remove();
        return;
      }

      if (naturalWidth < renderedWidth && naturalHeight < renderedHeight) {
        img.style.width = `${naturalWidth}px`;
        img.style.height = `${naturalHeight}px`;
      }
    };

    img.onerror = () => {
      img.remove();
    };
  });
};

export const PastePlugin = Extension.create({
  name: 'eventHandler',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handlePaste(view, event, slice) {
            const items = Array.from(event.clipboardData?.items || []);
            const imageItems = items.filter(
              (item) => item.type.indexOf('image') !== -1,
            );

            if (imageItems.length === 0) {
              return false;
            }

            event.preventDefault();

            imageItems.forEach((item) => {
              const file = item.getAsFile();
              if (!file) return;

              const { schema } = view.state;
              if (!schema.nodes.image) {
                console.warn('Image node type not found in schema.');
                return;
              }

              uploadFileToServer(file, `helparticle`)
                .then((imageUrl) => {
                  const imageNode = schema.nodes.image.create({
                    src: imageUrl,
                  });

                  const tr = view.state.tr;
                  if (tr.selection.empty) {
                    tr.insert(tr.selection.from, imageNode);
                  } else {
                    tr.replaceSelectionWith(imageNode);
                  }
                  view.dispatch(tr);
                })
                .catch((error) => {
                  console.error('Failed to upload image:', error);
                });
            });

            return true;
          },
        },
      }),
    ];
  },
});
