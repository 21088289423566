import { calculateContrast, hashToColor } from 'helper/Color';
import LRUCache from 'helper/LRUCache';
import { getRandomColor } from 'helper/RandomColorHelper';
import { LexoRank } from 'lexorank';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Bug } from 'models/Bug';
import { EmailTemplate } from 'models/EmailTemplate';
import { Feature } from 'models/Enums';
import { INTEGRATION } from 'models/Integration';
import { Invitation } from 'models/Invitations';
import {
  PaginationDataList,
  getSkipAndLimitFromPage,
} from 'models/PaginationDataList';
import {
  FCM_TOPIC,
  PostMessageAction,
  PostMessageData,
} from 'models/PostMessageData';
import { FeedbackType, Project } from 'models/Project';
import { ProjectAction } from 'models/ProjectAction';
import { ProjectTeam } from 'models/ProjectTeam';
import { TicketView } from 'models/TicketView';
import moment from 'moment';
import { toast } from 'react-toastify';
import { trackEvent } from 'services/GTagHelper';
import {
  getStreamedEventKeys,
  getUserPropertyFilters,
} from 'services/OutboundHttpService';
import {
  addDomain,
  addProjectType,
  aiTranslate,
  archiveFeedbackItems,
  clearHelpCenterCache,
  createAIFunction,
  createBot,
  createConversation,
  createProject,
  createProjectAnswer,
  createProjectCrawlerTask,
  deleteAIFunction,
  deleteBot,
  deleteCompletedBugs,
  deleteCustomDomainHelpCenterSettings,
  deleteCustomDomainSettings,
  deleteProject,
  deleteProjectAnswer,
  deleteProjectCrawlerTask,
  deleteUser,
  fetchAgentReport,
  getAIFunctions,
  getArchivedBugs,
  getBot,
  getBots,
  getCSVExportBoard,
  getCalendlyEventTypes,
  getExportBoard,
  getInvitedTeamMembers,
  getProject,
  getProjectAnswerStats,
  getProjectAnswers,
  getProjectCrawlerPages,
  getProjectCrawlerTasks,
  getProjectFeedbackUnreadStatus,
  getProjectUsers,
  getProjects,
  getProjectsUnreadCount,
  getTicketForProject,
  getTicketsCountForProject,
  getTicketsForProject,
  inviteTeam,
  leaveProject,
  loadTeamPreview,
  migrateIntegrations,
  removeDomain,
  resendIntegrations,
  searchForComments,
  searchForFeedbackItems,
  searchForHelpcenterArticles,
  searchForOutbounds,
  searchForSessions,
  searchSmartLinks,
  setUserRole,
  summarizeFeedback,
  updateAIFunction,
  updateBot,
  updateCustomDomainHelpCenterSettings,
  updateCustomDomainSettings,
  updateDomainSettings,
  updateProject,
  updateProjectAnswer,
  updateProjectPicture,
  uploadPDFAnswer,
  verifyDomain,
} from 'services/ProjectHttpService';
import { validateRecaptchaAction } from 'services/Recaptcha';
import WebSocketMessage from 'services/WebSocketMessage';
import WebSocketHelper, { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import { HttpContactViewService } from 'services/http.clients/http.contact-view.client';
import { HttpEmailTemplateClientService } from 'services/http.clients/http.email.template.client';
import { HttpProjectActionService } from 'services/http.clients/http.project.action.client';
import { HttpTeamService } from 'services/http.clients/http.team.client';
import { HttpTicketViewService } from 'services/http.clients/http.ticket.view.client';
import { HttpMessageTemplateService } from 'services/http.clients/message.template.client';
import Swal from 'sweetalert2';
import { MODALTYPE } from './ModalStore';
import {
  getFilterForInquiryType,
  inboxFilterStates,
} from 'pages/private/ProjectInbox/ProjectInbox';
import { passesSessionCondition } from 'helper/SessionConditionHelper';

const fetchingTicketDataNotification = {};
const notificationTicketCache = new LRUCache<string, any>(100);
const itemsInPage = 50;

const preprocessFilters = (filters) => {
  const newFilters = { ...filters };

  for (let key in newFilters) {
    if (newFilters.hasOwnProperty(key)) {
      const filterValue = newFilters[key];

      if (
        typeof filterValue === 'object' &&
        filterValue !== null &&
        filterValue.hasOwnProperty('$in')
      ) {
        newFilters[key] = filterValue.$in;
      }
    }
  }

  return newFilters;
};

const passesFilters = (ticket, filters) => {
  // Preprocess filters to clean up $in clauses
  filters = preprocessFilters(filters);

  // If filters object is empty, return true
  if (Object.keys(filters).length === 0) {
    return true;
  }

  // Loop over each key in the filters object
  for (let key in filters) {
    if (filters.hasOwnProperty(key)) {
      const filterValue = filters[key];
      let ticketValue = ticket[key];

      // Ensure ticket value is an array if the filter expects an array
      if (Array.isArray(filterValue) && !Array.isArray(ticketValue)) {
        ticketValue = [ticketValue]; // Convert non-array ticket values to an array
      }

      // Case 1: Filter is an array
      if (Array.isArray(filterValue)) {
        // Check if ticketValue array has at least one value matching filterValue
        if (!ticketValue.some((value) => filterValue.includes(value))) {
          return false;
        }
      }

      // Case 2: Special case for null or undefined
      else if (filterValue === null || filterValue === undefined) {
        if (ticketValue !== filterValue) {
          return false; // If ticket value doesn't match null or undefined, fail
        }
      }

      // Case 3: Direct comparison
      else {
        if (ticketValue !== filterValue) {
          return false; // If ticket value doesn't match filter value, fail
        }
      }
    }
  }

  // If all filters are passed
  return true;
};

const fetchTicketForNotification = async (shareToken, projectId) => {
  fetchingTicketDataNotification[shareToken] = true;

  try {
    const ticket = await getTicketForProject({
      projectId: projectId,
      shareToken: shareToken,
    });
    notificationTicketCache.set(shareToken, ticket);
    delete fetchingTicketDataNotification[shareToken];
    return ticket?.data ?? {};
  } catch (error) {
    delete fetchingTicketDataNotification[shareToken];
    return null;
  }
};

export const menuOptions = [
  {
    type: 'menu',
    config: {
      title: { localized: { en: 'Report an issue' } },
      description: { localized: { en: 'Found a bug? Let us know.' } },
      icon: 'https://static.gleap.io/bugcol.svg',
      actionType: 'bugreporting',
      color: '#F4CAC8',
    },
    targetAudience: 'all',
    conditions: [],
  },
  {
    type: 'menu',
    config: {
      title: { localized: { en: 'Request a feature' } },
      description: { localized: { en: 'What would you like to see next?' } },
      icon: 'https://static.gleap.io/ideacol.svg',
      actionType: 'featurerequests',
      color: '#FFEEC2',
    },
    targetAudience: 'all',
    conditions: [],
  },
  {
    type: 'menu',
    config: {
      title: { localized: { en: 'Ask a question' } },
      description: { localized: { en: 'We are here to help.' } },
      icon: 'https://static.gleap.io/questioncol.svg',
      actionType: 'BOT',
      color: '#EFE2FF',
      type: 'MENU',
    },
    targetAudience: 'all',
    conditions: [],
  },
  {
    type: 'menu',
    config: {
      title: { localized: { en: 'Redirect to URL' } },
      description: { localized: { en: 'Custom description' } },
      icon: 'https://static.gleap.io/linkcol.svg',
      actionType: 'REDIRECT_URL',
      color: '#E8FEE1',
      type: 'MENU',
    },
    targetAudience: 'all',
    conditions: [],
  },
  {
    type: 'menu',
    config: {
      title: { localized: { en: 'Custom option' } },
      description: { localized: { en: 'Custom description' } },
      icon: 'https://static.gleap.io/default.svg',
      actionType: 'CUSTOM_ACTION',
      color: '#E8FEE1',
      type: 'MENU',
    },
    targetAudience: 'all',
    conditions: [],
  },
  {
    type: 'app',
    config: {
      app: 'HELP_CENTER_SEARCH',
      label: {
        localized: {
          en: 'Search for help',
          de: 'Nach Hilfe suchen',
          cs: 'Hledat pomoc',
          es: 'Buscar ayuda',
          fr: "Rechercher de l'aide",
          it: 'Cercare aiuto',
          nl: 'Zoeken naar hulp',
          lt: 'Ieškoti pagalbos',
        },
      },
    },
    targetAudience: 'all',
    conditions: [],
  },
  {
    type: 'app',
    config: {
      app: 'LATEST_NEWS',
    },
    targetAudience: 'all',
    conditions: [],
  },
];

var debounceCountUpdateTimeout: any = null;
var debouncedMethods = {};
var debouncedSearchMethod: any = null;
var shouldDebounce = true;

export const defaultMenuOptions = [
  menuOptions[0],
  menuOptions[1],
  menuOptions[2],
];

// eslint-disable-next-line import/prefer-default-export
export class ProjectStore implements WebSocketMessage {
  projects: Project[] = [];
  projectsUnreadCount: any = {};
  unreadStatus: any = {};
  localUnreadStatus: any = {};
  currentAnswerStats: any = {};
  teamPreview: any[] = [];
  currentProject: Project | undefined = undefined;
  currentProjectUsers: any[] = [];
  currentProjectTeams: ProjectTeam[] = [];
  streamedEventKeys: {
    label: string;
    value: string;
    type: string;
    icon?: string;
  }[] = [];
  currentFeedbackType?: FeedbackType = undefined;
  stores: any = {};
  externalSessionId: string | null = null;
  loadingCurrentProject = false;
  loadingStatistics = false;
  loadingStats = false;
  loadingArchivedBugs = true;
  initialLoading = true;
  isProjectAdminLoading = true;
  isProjectAdmin = false;
  loadingFailed = false;
  isProjectAdminLoaded = false;
  canLeaveProject = false;
  loadingProjects = false;
  flowConfig: any = {};
  userFilterProperties: any[] = [];
  menuItems: any[] = [];
  configuratorLang = 'en';
  inquiryFilter = 'all';
  currentBoardFilter = { status: 'OPEN' };
  invitedTeamMembers: Invitation[] = [];
  editingProject: Project | undefined = undefined;
  isAddingDomain = false;
  isUpdatingSender = false;
  isVerifyingDomain = false;
  loadingAiResponse = false;
  aiPlanFailed = false;
  aiResponse: any = {};
  currentQAAnswers: any[] = [];
  crawlerTasks: any[] = [];
  crawlerPages: any[] = [];
  loadingQAAnswers = false;
  loadingCrawlerTasks = false;
  loadingCrawlerPages = false;
  nextTicketId = null;
  bots: any[] = [];
  loadingBots = false;
  bot: any = undefined;
  botNodesDraggable = true;
  calendlyEventTypes: any[] = [];
  currentLanguage: string = 'en';
  inboxTicketStatusSelection: string = 'OPEN';
  viewType = 'BOARD';
  contactViews: any[] = [];
  ticketViews: TicketView[] = [];
  currentSelectedView = 'ALL';
  aiFunctions: any[] = [];
  agentReport: any = {
    isLoading: false,
    data: [],
    hasMore: true,
    page: 0,
  };
  messageTemplates: any[] = [];

  archivedBugsDataList: PaginationDataList<Bug> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
    totalItems: 0,
  };

  emailTemplatesDataList: PaginationDataList<EmailTemplate> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };
  currentEmailTemplate: EmailTemplate | undefined = undefined;

  // ! TICKET PAGINATION-LIST ! //
  currentTicketsData: any = {}; // { [laneKey: string]: PaginationDataList<Bug> } = {};
  lastTicketDataFilter: any = {};
  currentTicketDataFilter: any = {};
  currentTicketDataSort: any = {};
  currentTicketSearchTerm: string = '';
  ticketSearchData: PaginationDataList<Bug> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 50,
    isLoading: false,
  };

  projectActions: ProjectAction[] = [];

  setNextTicketId = (id) => {
    this.nextTicketId = id;
  };

  globalSearchData: any = {
    bug: {
      data: [],
      isLoading: true,
    },
    session: {
      data: [],
      isLoading: true,
    },
    comment: {
      data: [],
      isLoading: true,
    },
    outbound: {
      data: [],
      isLoading: true,
    },
    helpcenterArticle: {
      data: [],
      isLoading: true,
    },
  };

  constructor() {
    makeAutoObservable(this);

    setInterval(() => {
      // Update unread items at least every 10 seconds.
      if (this.currentProject?.id) {
        this.getProjectUnreadStatus(this.currentProject?.id);
      }
    }, 10000);

    setInterval(() => {
      // Update unread items at least every 30 seconds.
      if (this.currentProject?.id && this.currentFeedbackType?.type) {
        this.updateTicketsCountDebounced();
      }
    }, 30000);

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        if (this.currentProject?.id) {
          this.getProjectUnreadStatus(this.currentProject?.id);
        }
        if (this.currentProject?.id && this.currentFeedbackType?.type) {
          this.updateTicketsCountDebounced();
        }
      }
    });
  }

  setViewType(newViewType) {
    try {
      const viewKey = `project-${this.currentProject?.id}-${this.currentFeedbackType?.type}-view`;
      localStorage.setItem(viewKey, newViewType);
    } catch (exp) {}

    this.viewType = newViewType;
  }

  getViewType() {
    return this.viewType;
  }

  setStatusType(newStatusType) {
    try {
      const statusKey = `project-${this.currentProject?.id}-${this.currentFeedbackType?.type}-status`;
      localStorage.setItem(statusKey, newStatusType);
    } catch (exp) {}

    this.currentBoardFilter = newStatusType;
  }

  setInboxTicketStatus(status: string) {
    this.inboxTicketStatusSelection = status;
  }

  getStatusType() {
    return this.currentBoardFilter;
  }

  updateUserFilterProperties = async () => {
    if (!this.currentProject?.id) {
      return;
    }

    const response = await getUserPropertyFilters(this.currentProject?.id);
    if (response && response.data) {
      runInAction(() => {
        this.userFilterProperties = response.data;
      });
    }
  };

  findTicketFromWebsocketEvent = async (websocketTicket) => {
    if (fetchingTicketDataNotification[websocketTicket.shareToken]) {
      // Already fetching ticket data. Wait for it to finish.
      while (fetchingTicketDataNotification[websocketTicket.shareToken]) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // Add some delay to make sure the ticket is fetched.
      await new Promise((resolve) => setTimeout(resolve, 300));
    }

    if (!this.currentProject?.id) {
      return;
    }

    // Check if the ticket is already in the current ticket data.
    if (!this.currentTicketsData) {
      // No current ticket data. Fetch the ticket from the API.
      return await fetchTicketForNotification(
        websocketTicket.shareToken,
        this.currentProject?.id,
      );
    }

    // Loop through all statuses to find the ticket by id
    for (const statusKey in this.currentTicketsData) {
      const statusData = this.currentTicketsData[statusKey]?.data;

      if (statusData) {
        const ticket = statusData.find((x) => x.id === websocketTicket.id);

        if (ticket) {
          const preparedTicket = {
            ...ticket,
            ...websocketTicket,
          };
          return preparedTicket;
        }
      }
    }

    // Check if the ticket is in the notification cache.
    const cachedTicket = notificationTicketCache.get(
      websocketTicket.shareToken,
    );
    if (cachedTicket) {
      return {
        ...cachedTicket,
        ...websocketTicket,
      };
    }

    // No ticket found. Fetch the ticket from the API.
    return await fetchTicketForNotification(
      websocketTicket.shareToken,
      this.currentProject?.id,
    );
  };

  ticketDoesPassCurrentFilter = async (feedbackItem) => {
    const currentFeedbackType = this.currentFeedbackType?.type ?? 'INQUIRY';

    // Type doesn not match. Skip.
    if (feedbackItem?.type !== currentFeedbackType) {
      return false;
    }

    try {
      const existingFeedbackItem = await this.findTicketFromWebsocketEvent(
        feedbackItem,
      );
      if (!existingFeedbackItem) {
        return false;
      }

      // Update data in the cache.
      notificationTicketCache.set(
        existingFeedbackItem.shareToken,
        existingFeedbackItem,
      );

      // Merge the data.
      Object.assign(feedbackItem, existingFeedbackItem);

      // Status filter
      if (
        this.currentTicketDataFilter?.status?.length &&
        existingFeedbackItem?.status !== undefined &&
        !this.currentTicketDataFilter.status.includes(
          existingFeedbackItem?.status,
        )
      ) {
        return false;
      }

      // Processing user filter
      if (
        Array.isArray(this.currentTicketDataFilter?.processingUser) &&
        this.currentTicketDataFilter.processingUser.length > 0
      ) {
        if (
          !existingFeedbackItem ||
          !this.currentTicketDataFilter.processingUser.includes(
            existingFeedbackItem?.processingUser?.id ||
              existingFeedbackItem?.processingUser?._id,
          )
        ) {
          return false;
        }
      }

      // Processing team filter
      if (
        this.currentTicketDataFilter?.processingTeam?.length &&
        existingFeedbackItem?.processingTeam !== undefined &&
        !this.currentTicketDataFilter.processingTeam.includes(
          existingFeedbackItem?.processingTeam,
        )
      ) {
        return false;
      }

      // Priority filter
      if (
        this.currentTicketDataFilter?.priority?.length &&
        existingFeedbackItem?.priority !== undefined &&
        !this.currentTicketDataFilter.priority.includes(
          existingFeedbackItem?.priority,
        )
      ) {
        return false;
      }

      // Mentions filter
      if (
        this.currentTicketDataFilter?.mentions?.length &&
        Array.isArray(existingFeedbackItem?.mentions) &&
        !existingFeedbackItem?.mentions.some((mention) =>
          this.currentTicketDataFilter.mentions.includes(mention),
        )
      ) {
        return false;
      }

      // Tags filter
      if (
        this.currentTicketDataFilter?.tags?.length &&
        (!Array.isArray(existingFeedbackItem?.tags) ||
          existingFeedbackItem?.tags.length === 0 ||
          !existingFeedbackItem?.tags.some((tag) =>
            this.currentTicketDataFilter.tags.includes(tag),
          ))
      ) {
        return false;
      }

      // URL filter
      if (
        this.currentTicketDataFilter?.url &&
        existingFeedbackItem?.metaData?.currentUrl !== undefined &&
        (existingFeedbackItem.metaData.currentUrl === null ||
          !existingFeedbackItem.metaData.currentUrl.includes(
            this.currentTicketDataFilter.url,
          ))
      ) {
        return false;
      }

      // Title filter
      if (
        this.currentTicketDataFilter?.title &&
        typeof existingFeedbackItem?.title === 'string' &&
        !existingFeedbackItem.title.includes(this.currentTicketDataFilter.title)
      ) {
        return false;
      }

      // Search filter
      if (
        this.currentTicketSearchTerm &&
        this.currentTicketSearchTerm.length > 0
      ) {
        const feedbackItemCopy = JSON.parse(
          JSON.stringify(existingFeedbackItem),
        );
        delete feedbackItemCopy.form;
        const searchString = JSON.stringify(feedbackItemCopy).toLowerCase();

        if (
          !searchString.includes(this.currentTicketSearchTerm.toLowerCase())
        ) {
          return false;
        }
      }

      // Session filter
      if (this.currentTicketDataFilter) {
        for (const key in this.currentTicketDataFilter) {
          if (key.startsWith('session.')) {
            const filterValue = this.currentTicketDataFilter[key];
            const sessionValue = this.getNestedProperty(feedbackItem, key);

            if (typeof filterValue === 'object' && filterValue !== null) {
              if (!passesSessionCondition(sessionValue, filterValue)) {
                return false;
              }
            } else {
              if (sessionValue !== filterValue) {
                return false;
              }
            }
          }
        }
      }
    } catch (exp) {
      console.error(exp);
    }

    return true;
  };

  getNestedProperty(obj, path) {
    return path
      .split('.')
      .reduce(
        (current, key) =>
          current && current[key] !== undefined ? current[key] : undefined,
        obj,
      );
  }

  prepareCurrentTicketsData = (args: { forceFetch?: boolean }) => {
    shouldDebounce = true;
    this.currentTicketsData = {};

    runInAction(() => {
      if (
        this.currentFeedbackType &&
        this.currentFeedbackType.options &&
        this.currentFeedbackType.options.possibleLanes
      ) {
        for (const possibleLaneKey in this.currentFeedbackType.options
          .possibleLanes) {
          const possibleLane =
            this.currentFeedbackType.options.possibleLanes[possibleLaneKey];
          this.currentTicketsData[possibleLane.key] = {
            data: [],
            pageIndex: 0,
            itemsInPage: 50,
            isLoading: true,
            metaData: {
              currentFeedbackType: this.currentFeedbackType?.type,
              appliedQuery: null,
            },
          };
        }
      }

      this.setInitalSorting();

      for (const laneKey in this.currentTicketsData) {
        this.fetchAndSetTicketsDataForLane({
          laneKey,
          loadMore: false,
          forceFetch: args.forceFetch,
        });
      }
    });
  };

  getCalendlyEventTypes = async () => {
    if (!this.currentProject) {
      return;
    }

    try {
      this.calendlyEventTypes = [];

      const response = await getCalendlyEventTypes(this.currentProject.id);
      if (response.status === 200) {
        runInAction(() => {
          this.calendlyEventTypes = response.data.eventTypes;
        });
      }
    } catch (exp) {}
  };

  cloneActionType = async ({ actionTypeId }) => {
    try {
      const actionType = this.projectActions.find(
        (action) => action.actionId === actionTypeId,
      );
      if (!actionType) {
        return;
      }

      const clonedActionType = JSON.parse(JSON.stringify(actionType));
      delete clonedActionType._id;
      delete clonedActionType.actionId;

      const newActionType = await HttpProjectActionService.getInstance().create(
        {
          data: clonedActionType,
        },
      );
      if (!newActionType) {
        return;
      }

      runInAction(() => {
        this.projectActions.push(newActionType);
      });

      return newActionType.actionId;
    } catch (error) {
      throw error;
    }
  };

  getCurrentViews = () => {
    let views: any[] = [];
    const currentFeedbackType = this.currentFeedbackType?.type ?? 'INQUIRY';

    if (currentFeedbackType === 'INQUIRY') {
      // Add default views for inquiry.
      inboxFilterStates.forEach((state) => {
        const filter = getFilterForInquiryType(
          state.value,
          this.stores.usersStore.currentUser.id,
        );

        views.push({
          name: `inbox_${state.value}`,
          filter: filter,
        });
      });

      // Push team views.
      const teams = this.currentProjectTeams ?? [];
      for (let team of teams) {
        if (team && team._id) {
          views.push({
            name: `team_${team._id}`,
            filter: {
              processingTeam: team._id,
            },
          });
        }
      }
    } else {
      try {
        // Default views for other types.
        const filterData = this.currentTicketDataFilter ?? {};
        const filterQuery = this.getFilterObjectFromData(filterData);
        if (filterQuery.filter) {
          views.push({
            name: `view_all`,
            filter: filterQuery.filter,
          });
        } else {
          views.push({
            name: `view_all`,
            filter: {},
          });
        }
      } catch (exp) {}
    }

    // Push custom views.
    const ticketViews = this.getTicketViewsForFeedbackType(currentFeedbackType);
    if (ticketViews && ticketViews.length > 0) {
      for (let view of ticketViews) {
        views.push({
          name: `view_${view._id}`,
          filter: view.conditions,
        });
      }
    }

    return views;
  };

  private lastUpdatedUnreadStatus: number | null = null;

  getProjectUnreadStatus = async (id?: string) => {
    try {
      const now = Date.now();

      // Prevent multiple requests in a short time.
      if (
        this.lastUpdatedUnreadStatus &&
        now - this.lastUpdatedUnreadStatus < 2000
      ) {
        return;
      }

      this.lastUpdatedUnreadStatus = now;

      // Update project unread counts.
      if (!this.currentFeedbackType?.type || !id) {
        return;
      }

      const currentFeedbackType = this.currentFeedbackType?.type ?? 'INQUIRY';
      const views = this.getCurrentViews();

      const response = await getProjectFeedbackUnreadStatus(id, {
        ticketType: currentFeedbackType,
        views: views,
      });
      if (response.status === 200) {
        const data = response.data;

        let newUnreadStatus = {};
        let projectData = data?.project ?? [];
        if (projectData && projectData.length > 0) {
          for (let i = 0; i < projectData.length; i++) {
            const projectItem = projectData[i];
            if (projectItem.type) {
              newUnreadStatus[projectItem.type] = projectItem.count ?? 0;
            }
          }
        }

        runInAction(() => {
          this.unreadStatus = newUnreadStatus;
          this.localUnreadStatus = response.data?.local ?? {};
        });
      }
    } catch (exp) {
      console.log(exp);
    }
  };

  setInitalSorting = () => {
    if (this.currentTicketDataSort?.path === this.currentFeedbackType?.path) {
      return;
    }

    try {
      switch (this.currentFeedbackType?.type) {
        case 'INQUIRY':
          this.currentTicketDataSort.sortKey = 'lastNotification';
          this.currentTicketDataSort.sortDirection = 'desc';
          this.currentTicketDataSort.path = this.currentFeedbackType?.path;
          break;

        case 'FEATURE_REQUEST':
          this.currentTicketDataSort.sortKey = 'lexorank';
          this.currentTicketDataSort.sortDirection = 'asc';
          this.currentTicketDataSort.path = this.currentFeedbackType?.path;
          break;

        default:
          this.currentTicketDataSort.sortKey = 'lexorank';
          this.currentTicketDataSort.sortDirection = 'asc';
          this.currentTicketDataSort.path = this.currentFeedbackType?.path;
          break;
      }
    } catch (exp) {}
  };

  locallySortTickets = () => {
    if (
      !this.currentTicketDataSort?.sortKey ||
      !this.currentTicketDataSort?.sortDirection
    ) {
      return;
    }

    for (const laneKey in this.currentTicketsData) {
      const currentLane = this.currentTicketsData[laneKey];

      if (currentLane) {
        switch (this.currentTicketDataSort?.sortKey) {
          case 'lexorank':
            currentLane.data.sort((a, b) => {
              if (!a || !b || !a.lexorank || !b.lexorank) {
                return 0;
              }

              return a.lexorank.localeCompare(b.lexorank);
            });
            break;

          case 'lastNotification':
            currentLane.data.sort(function (a, b) {
              return (
                new Date(b.lastNotification).getTime() -
                new Date(a.lastNotification).getTime()
              );
            });
            break;

          case 'notificationsUnread':
            currentLane.data.sort(function (a, b) {
              return b.notificationsUnread - a.notificationsUnread;
            });
            break;

          default:
            break;
        }

        runInAction(() => {
          currentLane.data = [...currentLane.data];
        });
      }
    }
  };

  fetchAndSetTicketsDataForLane = async (args: {
    laneKey: string;
    loadMore: boolean;
    forceFetch?: boolean;
    viewId?: string;
  }) => {
    if (shouldDebounce) {
      if (args.laneKey) {
        // Clear previous timer.
        if (debouncedMethods[args.laneKey]) {
          clearTimeout(debouncedMethods[args.laneKey]);
        }

        // Set new load request.
        debouncedMethods[args.laneKey] = setTimeout(() => {
          this.fetchAndSetTicketsDataForLaneDebounced(args);
        }, 650);
      }
    } else {
      this.fetchAndSetTicketsDataForLaneDebounced(args);
    }
  };

  fetchAndSetTicketsDataForLaneDebounced = async (args: {
    laneKey: string;
    loadMore: boolean;
    forceFetch?: boolean;
    viewId?: string;
  }) => {
    debouncedMethods[args.laneKey] = null;
    const { laneKey, loadMore, viewId } = args;
    const currentTicketsData = this.currentTicketsData[laneKey];
    if (!currentTicketsData) {
      return;
    }

    const isLoading =
      currentTicketsData?.isLoading && currentTicketsData.data.length !== 0;

    if (
      (!currentTicketsData || isLoading || !this.currentProject) &&
      !args.forceFetch
    ) {
      return;
    }

    var data = currentTicketsData?.data ?? [];
    var pageIndex = currentTicketsData?.pageIndex ?? 0;
    var itemsInPage = currentTicketsData?.itemsInPage ?? 0;

    if (loadMore) {
      // Check if already fetched all data
      if (data.length < itemsInPage * pageIndex) {
        return;
      }

      pageIndex++;
    } else {
      pageIndex = 0;
      data = [];
    }

    const loadDataForType = JSON.parse(
      JSON.stringify(this.currentFeedbackType?.type),
    );

    let query = {
      type: loadDataForType,
      status: laneKey,
      ...getSkipAndLimitFromPage({
        pageIndex,
        itemsInPage,
      }),
    };

    const filterQuery = this.getTicketFilterQuery();

    query = { ...query, ...filterQuery };

    if (
      JSON.stringify(currentTicketsData?.metaData?.appliedQuery) ===
      JSON.stringify(query)
    ) {
      return;
    }

    runInAction(() => {
      if (!loadMore) {
        this.currentTicketsData[laneKey].data = [];
      }
      this.currentTicketsData[laneKey].isLoading = true;
      this.currentTicketsData = { ...this.currentTicketsData };
    });

    const response = await getTicketsForProject({
      projectId: this.currentProject!.id,
      query,
    });

    if (loadDataForType !== this.currentFeedbackType?.type) {
      return;
    }

    if (
      JSON.stringify(currentTicketsData.metaData.appliedQuery) ===
      JSON.stringify(query)
    ) {
      // Assume that the query has been changed in the meantime and there is another request in progress.
      return;
    }

    runInAction(() => {
      if (
        this.currentTicketsData[laneKey] &&
        response.data &&
        response.data.tickets &&
        (!viewId || viewId === this.currentSelectedView)
      ) {
        this.currentTicketsData[laneKey].metaData.appliedQuery = query;
        this.currentTicketsData[laneKey].data = data.concat(
          response.data.tickets,
        );
        this.currentTicketsData[laneKey].pageIndex = pageIndex;
        this.currentTicketsData[laneKey].count = response.data.totalCount;
        this.currentTicketsData[laneKey].isLoading = false;
      }
    });
  };

  filterTicketsDataInternal = (viewId?: string) => {
    this.lastTicketDataFilter = JSON.stringify(this.currentTicketDataFilter);

    runInAction(() => {
      for (const laneKey in this.currentTicketsData) {
        // Reset data in lanes.
        this.currentTicketsData[laneKey].data = [];
        this.currentTicketsData[laneKey].count = 0;
        this.currentTicketsData[laneKey].pageIndex = 0;
        this.currentTicketsData[laneKey].isLoading = true;
        this.currentTicketsData[laneKey].metaData.appliedQuery = {};
        this.currentTicketsData[laneKey].metaData.view =
          this.currentSelectedView;

        this.fetchAndSetTicketsDataForLane({
          laneKey,
          loadMore: false,
          forceFetch: true,
          viewId,
        });
      }
    });
  };

  filterTicketsData = debounce(() => {
    this.getProjectUnreadStatus(this.currentProject?.id);

    this.filterTicketsDataInternal();
  }, 500);

  updateTicketsCountDebounced = async () => {
    if (!this.currentProject || !this.currentFeedbackType?.type) {
      return;
    }

    if (debounceCountUpdateTimeout) {
      clearTimeout(debounceCountUpdateTimeout);
    }

    debounceCountUpdateTimeout = setTimeout(async () => {
      const loadDataForType = JSON.parse(
        JSON.stringify(this.currentFeedbackType?.type),
      );

      let query = {
        type: loadDataForType,
      };
      const filterQuery = this.getTicketFilterQuery();
      query = { ...query, ...filterQuery };

      if (loadDataForType !== this.currentFeedbackType?.type) {
        return;
      }

      const response = await getTicketsCountForProject({
        projectId: this.currentProject!.id,
        query,
      });

      // Update local data.
      runInAction(() => {
        try {
          if (response.data && response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              const countItem = response.data[i];
              if (
                countItem &&
                countItem._id &&
                this.currentTicketsData[countItem._id]
              ) {
                this.currentTicketsData[countItem._id].count = countItem.count;
              }
            }
          }
        } catch (exp) {}
      });
    }, 1000);
  };

  getCurrentFilter = () => {
    try {
      const filterKey = `project-${this?.currentProject?.id}-${this?.currentFeedbackType?.type}-filters`;
      const filterData = localStorage.getItem(filterKey);
      if (filterData) {
        return JSON.parse(filterData);
      }
    } catch (exp) {}

    return {};
  };

  getFilterObjectFromData = (data) => {
    let query: any = {};

    let filter: any = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        // Check if the key is for a date range
        if (key === 'url') {
          // Special handling for the 'url' key
          filter['metaData.currentUrl'] = {
            $regex: value,
            $options: 'i',
          };
        } else if (key === 'title') {
          // Special handling for the 'url' key
          filter['title'] = {
            $regex: value,
            $options: 'i',
          };
        } else if (
          value &&
          typeof value === 'object' &&
          value.gte &&
          value.lte
        ) {
          filter[key] = {
            $gte: value.gte,
            $lte: value.lte,
          };
        } else {
          filter[key] = Array.isArray(value) ? { $in: value } : value;
        }
      }
    }

    // Cleanup major filters.
    if (filter.type) {
      query['type'] = filter.type;
      delete filter.type;
    }

    // Cleanup major filters.
    if (filter.status) {
      query['status'] = filter.status;
      delete filter.status;
    }

    return { query, filter };
  };

  getTicketFilterQuery = () => {
    const { query, filter } = this.getFilterObjectFromData(
      this.currentTicketDataFilter,
    );

    query['filter'] = JSON.stringify(filter);

    // Sort
    if (
      this.currentTicketDataSort &&
      this.currentTicketDataSort.sortKey &&
      this.currentTicketDataSort.sortDirection
    ) {
      if (this.currentTicketDataSort.sortKey === 'lexorank') {
        query['sort'] = `lexorank`;
      } else {
        query['sort'] = `${
          this.currentTicketDataSort.sortDirection === 'asc' ? '' : '-'
        }${this.currentTicketDataSort.sortKey}`;
      }
    }

    return query;
  };

  getLanesForFeedbackType = () => {
    if (
      this.currentFeedbackType &&
      this.currentFeedbackType.options &&
      this.currentFeedbackType.options.possibleLanes
    ) {
      return this.currentFeedbackType.options.possibleLanes;
    }

    return [];
  };

  filterTicketsForAllLanes = async (args: { searchTerm: string }) => {
    const { searchTerm } = args;
    if (!this.currentProject) {
      return;
    }

    // Set search term.
    this.currentTicketSearchTerm = searchTerm;

    if (debouncedSearchMethod) {
      clearTimeout(debouncedSearchMethod);
      debouncedSearchMethod = null;
    }

    debouncedSearchMethod = setTimeout(() => {
      for (const laneKey in this.currentTicketsData) {
        this.searchForTickets({
          searchTerm,
          laneKey,
        });
      }
    }, 800);
  };

  searchForTicketsForAllLanes = async (args: { searchTerm: string }) => {
    const { searchTerm } = args;
    if (!this.currentProject) {
      return;
    }

    // Set search term.
    this.currentTicketSearchTerm = searchTerm;

    if (debouncedSearchMethod) {
      clearTimeout(debouncedSearchMethod);
      debouncedSearchMethod = null;
    }

    debouncedSearchMethod = setTimeout(() => {
      for (const laneKey in this.currentTicketsData) {
        this.searchForTickets({
          searchTerm,
          laneKey,
        });
      }
    }, 800);
  };

  searchForTickets = async (args: { searchTerm: string; laneKey: string }) => {
    const { laneKey, searchTerm } = args;
    if (!this.currentProject) {
      return;
    }

    if (searchTerm === '') {
      for (const laneKey in this.currentTicketsData) {
        this.fetchAndSetTicketsDataForLane({
          laneKey,
          loadMore: false,
        });
      }
      return;
    }

    try {
      this.currentTicketsData[laneKey].isLoading = true;
      this.currentTicketsData[laneKey].pageIndex = 0;

      const response = await searchForFeedbackItems({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
          type: this.currentFeedbackType?.type,
          archived: false,
          status: laneKey,
        },
      });

      runInAction(() => {
        this.currentTicketsData[laneKey].data =
          response.data && response.data.hits ? response.data.hits : [];
        this.currentTicketsData[laneKey].isLoading = false;
      });
    } catch (_) {
      this.currentTicketsData[laneKey].isLoading = false;
    }
  };

  duplicateTicketSearch = async (args: { searchTerm: string; query?: any }) => {
    const { searchTerm, query } = args;
    if (!this.currentProject) {
      return;
    }

    try {
      runInAction(() => {
        this.ticketSearchData.isLoading = true;
      });

      const response = await searchForFeedbackItems({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
          archived: false,
          ...query,
        },
      });

      runInAction(() => {
        this.ticketSearchData.data =
          response.data && response.data.hits ? response.data.hits : [];
        this.ticketSearchData.isLoading = false;
      });
    } catch (_) {
      runInAction(() => {
        this.ticketSearchData.data = [];
        this.ticketSearchData.isLoading = false;
      });
    }
  };

  setCurrentProject = (currentProject) => {
    if (!currentProject) {
      // Resets
      this.clear();
    }

    this.currentProject = currentProject;
    this.userFilterProperties = [];

    if (currentProject?.id) {
      WebSocketHelper.getInstance().subscribeToProject(currentProject?.id);
    }

    // Set current project in storage
    if (currentProject) {
      localStorage.setItem('@gleap-project', currentProject?.id);
    }

    // Set global styles
    const color = this.currentProject?.flowConfig.color ?? '#00bcd4';
    const contrastColor = calculateContrast(color);
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty(
      '--contrast-color',
      contrastColor,
    );

    // Ensure that the correct organisation is loaded.
    if (
      this.currentProject?.organisation?.id &&
      this.stores.organisationStore?.currentOrganisation?.id &&
      this.currentProject?.organisation?.id !==
        this.stores.organisationStore?.currentOrganisation?.id
    ) {
      // Load new organisation due to orga change.
      this.stores.organisationStore.getOrganisation(
        this.currentProject?.organisation?.id,
      );
    }
  };

  setExternalSessionId(externalSessionId: string | null) {
    this.externalSessionId = externalSessionId;
  }

  setFeedbackTypeForPath(path) {
    if (!this.currentProject?.projectTypes) {
      return null;
    }

    this.resetCurrentSelectedView();

    for (let i = 0; i < this.currentProject?.projectTypes?.length; i++) {
      const currentProjectType = this.currentProject?.projectTypes[i];
      if (currentProjectType.path === path) {
        this.currentFeedbackType = currentProjectType;
        this.lastUpdatedUnreadStatus = null;
        this.currentTicketDataFilter = {};
        this.lastTicketDataFilter = {};
        this.currentTicketDataSort = {};
        this.currentTicketsData = {};
        notificationTicketCache.clear();

        // Give filters and sort some time to be set.
        setTimeout(() => {
          this.prepareCurrentTicketsData({});

          // Type change, reload unread status.
          this.getProjectUnreadStatus(this.currentProject?.id);
        }, 250);
        return currentProjectType;
      }
    }

    return null;
  }

  findFeedbackTypeForType(type) {
    if (!this.currentProject?.projectTypes) {
      return null;
    }

    for (let i = 0; i < this.currentProject?.projectTypes?.length; i++) {
      const currentProjectType = this.currentProject?.projectTypes[i];
      if (currentProjectType.type === type) {
        return currentProjectType;
      }
    }

    return null;
  }

  findReadableStatusForTypeAndKey(key, ticketType) {
    if (!this.currentProject?.projectTypes) {
      return null;
    }

    for (let i = 0; i < this.currentProject?.projectTypes?.length; i++) {
      const currentProjectType = this.currentProject?.projectTypes[i];
      if (currentProjectType.type === ticketType) {
        if (
          currentProjectType.options &&
          currentProjectType.options.possibleLanes
        ) {
          for (const possibleLaneKey in currentProjectType.options
            .possibleLanes) {
            const possibleLane =
              currentProjectType.options.possibleLanes[possibleLaneKey];
            if (possibleLane.key === key) {
              return possibleLane.title;
            }
          }
        }
      }
    }

    return key;
  }

  setStores(stores) {
    this.stores = stores;
  }

  getLocalBugData = (bugId, status) => {
    if (
      this.currentTicketsData &&
      this.currentTicketsData[status] &&
      this.currentTicketsData[status].data
    ) {
      let ticket = this.currentTicketsData[status].data.find(
        (x) => x.id === bugId || x.shareToken === bugId,
      );

      if (ticket) {
        return ticket;
      }
    }
    return null;
  };

  findLocalBugData = (bugId) => {
    for (const laneKey in this.currentTicketsData) {
      let ticket = this.currentTicketsData[laneKey].data.find(
        (x) => x.id === bugId || x.shareToken === bugId,
      );

      if (ticket) {
        return ticket;
      }
    }
    return null;
  };

  locallyUpdateUnreadStatus = (bugId, updateData) => {
    const existingTicket = this.findLocalBugData(bugId);

    // Ticket not found locally. Skip.
    if (!existingTicket) {
      return;
    }

    // Ticket data after update.
    const updatedTicket = {
      ...existingTicket,
      ...updateData,
    };

    // Update unread status if update data contains unread status.
    if (updateData.notificationsUnread) {
      // Remove unread status.
      this.updateLocalUnreadStatus(updatedTicket);
    } else {
      // Locally update notification status.
      if (existingTicket.notificationsUnread) {
        // Remove unread status.
        this.updateLocalUnreadStatus({
          ...existingTicket,
          notificationsUnread: false,
        });

        this.updateLocalUnreadStatus(updatedTicket);
      }
    }
  };

  locallyUpdateBug(
    bugId,
    data,
    force = false,
    fullTicketData: any | null = null,
  ) {
    this.locallyUpdateUnreadStatus(bugId, data);

    // Find and update bug in currentTicketsData and if status changed, move it to the correct lane
    for (const laneKey in this.currentTicketsData) {
      let ticket = this.currentTicketsData[laneKey].data.find(
        (x) => x.id === bugId || x.shareToken === bugId,
      );

      if (!ticket) {
        // Check if the ticket is already somewhere else
        let existingTicket;
        for (const laneKey in this.currentTicketsData) {
          existingTicket = this.currentTicketsData[laneKey].data.find(
            (x) => x.id === bugId || x.shareToken === bugId,
          );

          if (existingTicket) {
            break;
          }
        }

        // Only add if fullTicketData is available and does not exist in any lane yet.
        if (
          !existingTicket &&
          fullTicketData &&
          fullTicketData.type === this.currentFeedbackType?.type &&
          fullTicketData.status &&
          this.currentTicketsData[fullTicketData.status] &&
          fullTicketData.archived !== true
        ) {
          // Add the ticket
          runInAction(() => {
            this.currentTicketsData[fullTicketData.status].data = [
              ...this.currentTicketsData[fullTicketData.status].data,
              { ...fullTicketData },
            ];
            this.currentTicketsData[fullTicketData.status].count++;
          });
        }

        this.locallySortTickets();
        continue;
      }

      // Don't change notificationsUnread of a that is currently open.
      if (
        !force &&
        ticket?.id === this.stores.bugStore?.currentBug?.id &&
        data.notificationsUnread
      ) {
        data.notificationsUnread = false;
      }

      // Check if type changed
      if (data.type && ticket.type !== data.type) {
        // Remove from current lane
        const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
          (x) => x.id === bugId || x.shareToken === bugId,
        );

        if (bugIndex >= 0) {
          runInAction(() => {
            this.currentTicketsData[laneKey].data.splice(bugIndex, 1);
            this.currentTicketsData[laneKey].count--;
          });
        }
      }

      // Check if status changed
      else if (data.status && ticket.status !== data.status) {
        // Remove from current lane
        const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
          (x) => x.id === bugId || x.shareToken === bugId,
        );

        if (bugIndex >= 0) {
          runInAction(() => {
            this.currentTicketsData[laneKey].data.splice(bugIndex, 1);
            this.currentTicketsData[laneKey].count--;

            this.currentTicketsData[laneKey] = {
              ...this.currentTicketsData[laneKey],
            };
          });
        }

        // Add to new lane
        const newLaneKey = data.status;
        if (this.currentTicketsData[newLaneKey]) {
          runInAction(() => {
            this.currentTicketsData[newLaneKey].count++;

            this.currentTicketsData[newLaneKey].data = [
              ...this.currentTicketsData[newLaneKey].data,
              { ...ticket, ...data },
            ];
          });
        }
      } else {
        // Update ticket in current lane
        const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
          (x) => x.id === bugId || x.shareToken === bugId,
        );

        if (bugIndex >= 0) {
          runInAction(() => {
            this.currentTicketsData[laneKey].data[bugIndex] = {
              ...ticket,
              ...data,
            };
            this.currentTicketsData[laneKey].data = [
              ...this.currentTicketsData[laneKey].data,
            ];
          });
        }
      }

      break;
    }

    // Sort tickets
    this.locallySortTickets();
  }

  locallyDeleteBug(data) {
    // Find and delete bug in currentTicketsData
    for (const laneKey in this.currentTicketsData) {
      if (this.currentTicketsData[laneKey].data.length > 0) {
        const bugIndex = this.currentTicketsData[laneKey].data.findIndex(
          (x) => x.id === data.id,
        );
        if (bugIndex >= 0) {
          runInAction(() => {
            let count = this.currentTicketsData[laneKey].count ?? 0;
            if (count > 0) {
              count--;
            }

            this.currentTicketsData[laneKey].data.splice(bugIndex, 1);
            this.currentTicketsData[laneKey].count = count;
            this.currentTicketsData[laneKey].data = [
              ...this.currentTicketsData[laneKey].data,
            ];
          });
        }
      }
    }
  }

  updateLocalCount = (status, count) => {
    if (this.currentTicketsData[status].count) {
      let newCount = this.currentTicketsData[status].count + count;
      if (newCount < 0) {
        newCount = 0;
      }
      this.currentTicketsData[status].count = newCount;
    }
  };

  updateLocalGlobalUnreadStatus = (ticketData) => {
    if (!this.currentProject?.id) {
      return;
    }

    const changeCount = ticketData.notificationsUnread ? 1 : -1;

    // Update global unread status.
    let newVla = (this.unreadStatus[ticketData.type] ?? 0) + changeCount;
    if (newVla < 0) {
      newVla = 0;
    }
    this.unreadStatus = {
      ...this.unreadStatus,
      [ticketData.type]: newVla,
    };
  };

  updateLocalUnreadStatus = (ticketData) => {
    if (!this.currentProject?.id) {
      return;
    }

    const changeCount = ticketData.notificationsUnread ? 1 : -1;

    // Return if the ticket type does not match the current feedback type.
    if (!this.currentFeedbackType?.type) {
      return;
    }

    // Update local unread status only when type matches.
    if (this.currentFeedbackType?.type !== ticketData.type) {
      return;
    }

    // Update unread status if update data contains unread status.
    this.updateLocalGlobalUnreadStatus(ticketData);

    const views = this.getCurrentViews();

    let newUnreadStatus = { ...this.localUnreadStatus };

    for (let i = 0; i < views.length; i++) {
      const view = views[i];

      // Check if ticket matches filter.
      const filter = toJS(view.filter ?? {});

      if (passesFilters(ticketData, filter)) {
        const inboxKey = view.name;

        if (newUnreadStatus[inboxKey]) {
          // Check if the ticket status already exists in this inbox's unread status
          let found = false;
          for (let j = 0; j < newUnreadStatus[inboxKey].length; j++) {
            if (newUnreadStatus[inboxKey][j].status === ticketData.status) {
              // Increment count for the matching status
              let newVal = newUnreadStatus[inboxKey][j].count + changeCount;
              if (newVal < 0) {
                newVal = 0;
              }

              newUnreadStatus[inboxKey][j].count = newVal;
              found = true;
              break;
            }
          }

          // If the ticket status does not exist, add it
          if (!found && changeCount > 0) {
            newUnreadStatus[inboxKey].push({
              count: 1,
              status: ticketData.status,
            });
          }
        } else {
          if (changeCount > 0) {
            newUnreadStatus[inboxKey] = [
              {
                count: 1,
                status: ticketData.status,
              },
            ];
          }
        }
      }
    }

    runInAction(() => {
      this.localUnreadStatus = newUnreadStatus;
    });
  };

  onEvent = async (event: string, data: any) => {
    if (!data) {
      return;
    }

    if (event === WEBSOCKET_EVENTS.CRAWLER_UPDATED) {
      if (this.currentProject?.id === data.project) {
        this.getProjectCrawlerTasks(this.currentProject!.id);
      }
    }

    if (event === WEBSOCKET_EVENTS.BUG_UPDATED) {
      if (
        data.latestComment &&
        (typeof data.latestComment === 'string' ||
          data.latestComment instanceof String)
      ) {
        delete data.latestComment;
      }

      let ticketUpdate = { ...data };

      const ticketPassesFilter = await this.ticketDoesPassCurrentFilter(data);

      // Update unread status if changed and not current type.
      if (
        ticketUpdate.hasOwnProperty('notificationsUnread') &&
        this.currentFeedbackType?.type !== ticketUpdate.type
      ) {
        this.updateLocalGlobalUnreadStatus(ticketUpdate);
      }

      if (!ticketPassesFilter) {
        this.locallyDeleteBug(data);
      } else {
        // Check if we need to update the ticket locally or not. (might already be updated)
        let updateNeeded = false;

        // Update from server. Check if we need to update locally. (might already be updated)
        const existingTicket = this.getLocalBugData(data.id, data.status);
        if (!existingTicket) {
          updateNeeded = true;
        } else {
          for (const key in ticketUpdate) {
            if (
              ticketUpdate.hasOwnProperty(key) &&
              !['project', 'organisation'].includes(key)
            ) {
              if (existingTicket[key] !== ticketUpdate[key]) {
                updateNeeded = true;
                break;
              }
            }
          }
        }

        // Only update if needed.
        if (updateNeeded) {
          this.locallyUpdateBug(data.id, ticketUpdate, false, data);
        }
      }
    }

    if (event === WEBSOCKET_EVENTS.BUG_DELETED) {
      this.locallyDeleteBug(data);
    }

    if (event === WEBSOCKET_EVENTS.BUG_CREATED) {
      if (
        data &&
        data.id &&
        data.project &&
        this.currentProject?.id &&
        data.project === this.currentProject?.id &&
        data.archived !== true
      ) {
        const ticketPassesFilter = await this.ticketDoesPassCurrentFilter(data);

        if (
          data?.type === this.currentFeedbackType?.type &&
          this.currentTicketsData &&
          this.currentTicketsData[data.status] &&
          ticketPassesFilter
        ) {
          this.updateLocalCount(data.status, 1);

          // Insert bug
          runInAction(() => {
            if (!this.currentTicketsData[data.status].data) {
              this.currentTicketsData[data.status].data = [];
            }

            const exists = this.currentTicketsData[data.status].data.find(
              (ticket) => ticket?.id && ticket?.id === data.id,
            );
            if (!exists) {
              this.currentTicketsData[data.status].data = [
                ...this.currentTicketsData[data.status].data,
                data,
              ];
            }
          });

          // Sort tickets
          this.locallySortTickets();
        }
      }
    }

    if (event === WEBSOCKET_EVENTS.COLUMN_DELETED) {
      this.updateTicketsCountDebounced();

      if (data && data.project && data.project === this.currentProject?.id) {
        if (
          this.currentTicketsData &&
          this.currentTicketsData[data.status] &&
          this.currentTicketsData[data.status].data.length > 0
        ) {
          this.currentTicketsData[data.status].data = [];
        }
      }
    }

    // If sort by unread status, re-sort tickets.
    if (this.currentTicketDataSort?.sortKey === 'notificationsUnread') {
      this.locallySortTickets();
    }
  };

  isPlanExpired = () => {
    if (!this.currentProject?.organisation) {
      return false;
    }

    if (
      (this.currentProject?.organisation?.subscription?.planID === 'free' ||
        !this.currentProject?.organisation?.subscription?.planID) &&
      !this.isInTimeSpan()
    ) {
      return true;
    }
    return false;
  };

  isInTimeSpan = () => {
    if (!this.currentProject?.organisation?.subscription?.trialExpiresAt) {
      return false;
    }

    const expiresAt = moment(
      this.currentProject?.organisation?.subscription?.trialExpiresAt,
    );
    const today = moment(Date.now());
    return expiresAt >= today;
  };

  isIntegrationInPlan = (integration: INTEGRATION) => {
    try {
      const metadata =
        this.currentProject?.organisation?.subscription?.metadata;
      if (metadata && metadata.integrations) {
        if (
          metadata.integrations === 'some' &&
          (integration === INTEGRATION.JIRA ||
            integration === INTEGRATION.EMAIL ||
            integration === INTEGRATION.TRELLO ||
            integration === INTEGRATION.SLACK)
        ) {
          return true;
        }
        if (metadata.integrations === 'all') {
          return true;
        }
      }

      return false;
    } catch {
      return false;
    }
  };

  isFeatureInPlan = (feature: Feature, values?: string[]) => {
    try {
      const metadata =
        this.currentProject?.organisation?.subscription?.metadata;
      if (metadata && metadata[feature]) {
        if (metadata[feature] === 'true') {
          return true;
        }

        if (values && values.length > 0) {
          for (let i = 0; i < values.length; i++) {
            if (metadata[feature] === values[i]) {
              return true;
            }
            if (metadata[feature] !== 'false' && values[i] === 'number') {
              return true;
            }
          }
        }
      }
      return false;
    } catch {
      return false;
    }
  };

  inviteTeamMember = async (emails: any[], resend = false) => {
    let token = '';
    try {
      token = (await validateRecaptchaAction('inviteTeam')) as any;
    } catch (exp) {
      toast.error('Are you human?');
      return {
        list: [],
        success: false,
      };
    }

    try {
      const response = await inviteTeam(
        this.currentProject?.id!,
        emails,
        token,
      );

      this.stores.organisationStore!.getInvitedTeamMembers();
      this.stores.organisationStore!.getInvitedOrgaTeamMembers();
      this.getInvitedTeamMembers();

      trackEvent('invite.team_member', {
        organization_id: this.currentProject?.organisation?.id,
        project_id: this.currentProject?.id,
      });

      if (resend) {
        toast.success('Successfully sent invitation.');
      }
      return {
        list: response.data,
        success: true,
      };
    } catch (err: any) {
      return {
        list: [],
        success: false,
      };
    }
  };

  createConversation = async (sessionId, title) => {
    try {
      const response = await createConversation(
        this.currentProject?.id!,
        sessionId,
        title,
      );
      if (response.status === 200) {
        return response.data;
      }
      // eslint-disable-next-line no-empty
    } catch (err: any) {}
    return null;
  };

  getInvitedTeamMembers = async () => {
    try {
      const response = await getInvitedTeamMembers(this.currentProject?.id!);
      runInAction(() => {
        if (response.status === 200) {
          this.invitedTeamMembers = response.data;
        }
      });
      // eslint-disable-next-line no-empty
    } catch (err: any) {}
  };

  setUserRole = async (userID, role) => {
    try {
      const response = await setUserRole(this.currentProject!.id, userID, role);
      if (response.status === 200) {
        toast.success('Successfully updated user role.');
        this.getProjectUsers();
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      } else if (err && err.response.status === 409) {
        toast.error('You are the last admin in the project!');
      } else if (err && err.response.status === 404) {
        toast.error('Updating user role failed!');
      }
    }
  };

  removeUser = async (userID) => {
    try {
      const response = await deleteUser(this.currentProject!.id, userID);
      if (response.status === 200) {
        toast.success('Successfully removed user from project.');
        this.getProjectUsers();

        return true;
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      } else if (err && err.response.status === 409) {
        toast.error('You are the last admin in the project!');
      } else if (err && err.response.status === 404) {
        toast.error('The user was not found in the project!');
      }
    }

    return false;
  };

  getItemAtPosition = (cards: any[], index: number) => {
    if (cards && index >= 0 && index < cards.length) {
      if (typeof cards[index] !== 'undefined') {
        return cards[index];
      }
    }

    return null;
  };

  setLoadingStats = (loadingStats) => {
    this.loadingStats = loadingStats;
  };

  aiTranslate = async (data, targetLanguage, fallbackProjectId?) => {
    let projectId = this.currentProject?.id;
    if (!projectId) {
      projectId = fallbackProjectId;
      if (!projectId) {
        return null;
      }
    }

    try {
      if (projectId) {
        const response = await aiTranslate(projectId, data, targetLanguage);
        if (response.status === 200) {
          return response.data;
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 408) {
        toast.error('Plan usage limit exceeded.');
        return;
      }
      toast.error('Translation failed. Please try again.');
    }

    return null;
  };

  cloneBot = async (projectId, botId) => {
    try {
      const botToClone = this.bots.find((bot) => bot.id === botId);
      if (botToClone) {
        const newBotData = {
          status: 'draft',
          name: `Copy of ${botToClone.name}`,
          actionFlows: botToClone.actionFlows,
          triggerPriority: botToClone.triggerPriority,
          trigger: botToClone.trigger,
          targetAudience: botToClone.targetAudience,
          conditions: botToClone.conditions,
        };

        if (projectId) {
          const response = await createBot(projectId);
          if (response.status === 200) {
            await updateBot(projectId, response.data.id, newBotData);
            this.getBots(projectId);
          }
        }
      }
    } catch (error) {
      toast.error('Failed to duplicate bot.');
    }
  };

  getBots = async (projectId: string) => {
    this.loadingBots = true;

    try {
      const response = await getBots(projectId);
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.bots = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.loadingBots = false;
    });
  };

  getBot = async (projectId: string, botId: string) => {
    try {
      this.bot = undefined;

      const response = await getBot(projectId, botId);
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.bot = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  getProjectAnswerStats = async (projectId: string) => {
    try {
      this.currentAnswerStats = {};

      const response = await getProjectAnswerStats(projectId);
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.currentAnswerStats = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  deleteProjectCrawlerTask = async (
    projectId: string,
    crawlerTaskId: string,
  ) => {
    try {
      runInAction(() => {
        this.crawlerTasks = [
          ...this.crawlerTasks.filter((a) => a.id !== crawlerTaskId),
        ];
      });

      await deleteProjectCrawlerTask(projectId, crawlerTaskId);
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  getProjectCrawlerTasks = async (projectId: string) => {
    try {
      this.crawlerPages = [];
      this.crawlerTasks = [];
      this.loadingCrawlerTasks = true;

      const response = await getProjectCrawlerTasks(projectId);
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.crawlerTasks = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.loadingCrawlerTasks = false;
    });
  };

  createCrawlerTask = async (
    projectId: string,
    url: string,
    followLinks: boolean,
    followSubdomains: boolean,
    isSitemap: boolean,
    waitForResponse: boolean = true,
  ) => {
    try {
      const response = await createProjectCrawlerTask(projectId, {
        url,
        followLinks,
        followSubdomains,
        isSitemap,
        waitForResponse,
      });
      if (response.status === 200) {
        runInAction(() => {
          this.crawlerTasks = [...this.crawlerTasks, response.data];
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {
      Swal.fire({
        title: 'Adding external page failed',
        text: 'You have reached the maximum number of websites you can add. Please reach out to us to upgrade your plan.',
        icon: 'error',
      });
    }
  };

  getProjectCrawledPages = async (projectId: string, crawlerTaskId: string) => {
    try {
      this.crawlerPages = [];
      this.loadingCrawlerPages = true;

      const response = await getProjectCrawlerPages(
        projectId,
        crawlerTaskId,
        1000,
        0,
      );
      if (response.status === 200) {
        runInAction(() => {
          if (response.data) {
            this.crawlerPages = response.data;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.loadingCrawlerPages = false;
    });
  };

  uploadPDFToAnswers = async (fileData: string, fileName: string) => {
    const res = await uploadPDFAnswer(
      this.currentProject?.id,
      fileData,
      fileName,
    );
    if (res.status === 200) {
      this.currentQAAnswers = [res.data, ...this.currentQAAnswers];
      toast.success('Successfully uploaded PDF.');
      return res.data;
    } else {
      toast.error('Uploading PDF failed.');
    }
  };

  loadMoreQAAnswers = async (
    projectId: string,
    unanswered,
    skip,
    limit,
    kaiSuggestion?: boolean,
  ) => {
    try {
      this.loadingQAAnswers = true;

      const response = await getProjectAnswers(
        projectId,
        unanswered,
        skip,
        limit,
        kaiSuggestion,
      );
      if (response.status === 200) {
        runInAction(() => {
          if (response.data && response.data.answers) {
            this.currentQAAnswers = [
              ...this.currentQAAnswers,
              ...response.data.answers,
            ];
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.loadingQAAnswers = false;
    });
  };

  getQAAnswers = async (
    projectId: string,
    unanswered,
    skip,
    limit,
    kaiSuggestion?: boolean,
  ) => {
    try {
      this.currentQAAnswers = [];
      this.loadingQAAnswers = true;

      const response = await getProjectAnswers(
        projectId,
        unanswered,
        skip,
        limit,
        kaiSuggestion,
      );
      if (response.status === 200) {
        runInAction(() => {
          if (response.data && response.data.answers) {
            this.currentQAAnswers = response.data.answers;
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.loadingQAAnswers = false;
    });
  };

  deleteQAAnswer = async (projectId: string, answerId: string) => {
    try {
      runInAction(() => {
        this.currentQAAnswers = [
          ...this.currentQAAnswers.filter((a) => a.id !== answerId),
        ];
      });

      await deleteProjectAnswer(projectId, answerId);
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  updateQAAnswer = async (projectId: string, answerId: string, data: any) => {
    try {
      runInAction(() => {
        for (var i in this.currentQAAnswers) {
          if (this.currentQAAnswers[i].id === answerId) {
            this.currentQAAnswers[i] = {
              ...this.currentQAAnswers[i],
              ...data,
            };
            this.currentQAAnswers[i].acknowledged = true;
            break;
          }
        }

        this.currentQAAnswers = [...this.currentQAAnswers];
      });

      await updateProjectAnswer(projectId, answerId, data);
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  createQAAnswer = async (
    projectId: string,
    question: string,
    answer: string,
    sourceLink: string,
    sourceName: string,
    tags: string[],
  ) => {
    try {
      const response = await createProjectAnswer(projectId, {
        question,
        answer,
        sourceLink,
        sourceName,
        tags,
      });
      if (response.status === 200) {
        runInAction(() => {
          this.currentQAAnswers = [...this.currentQAAnswers, response.data];
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  updateBot = async (
    projectId: string,
    botId: string,
    data: {
      status: 'draft' | 'live';
      name?: string;
      actionFlows?: any;
      triggerPriority?: number;
      trigger?: string;
      triggerType?: string;
      triggerFilter?: any;
      triggerDelaySettings?: any;
      targetAudience?: string;
      conditions?: any[];
    },
  ) => {
    try {
      const response = await updateBot(projectId, botId, data);
      if (response.status === 200) {
        runInAction(() => {
          this.bot = response.data;

          // Replace bot in list.
          const index = this.bots.findIndex((b) => b.id === this.bot.id);
          if (index !== -1) {
            this.bots[index] = this.bot;
          }
        });

        toast.success('Successfully updated bot.');
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {
      toast.error('Updating bot failed.');
    }
  };

  createBot = async (projectId: string) => {
    try {
      const response = await createBot(projectId);
      if (response.status === 200) {
        runInAction(() => {
          this.bots = [...this.bots, response.data];

          // Navigate to bot.
          this.stores.navigate(
            `/projects/${this.currentProject?.id}/bots/custombots/${response.data?.id}/`,
          );
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  deleteBot = async (botId: string) => {
    try {
      const response = await deleteBot(this.currentProject?.id, botId);
      if (response.status === 200) {
        runInAction(() => {
          this.bots = this.bots.filter((b) => b.id !== botId);
        });
      }

      toast.success('Successfully deleted bot.');
    } catch (exp) {
      toast.error('Deleting bot failed.');
    }
  };

  async summarizeFeedback() {
    if (!this.currentProject) {
      return;
    }

    this.loadingAiResponse = true;

    try {
      const response = await summarizeFeedback(this.currentProject.id);
      runInAction(() => {
        this.aiResponse = response.data;
      });
    } catch (error) {
      if (
        (error as any) &&
        (error as any)?.response &&
        (error as any)?.response.status === 408
      ) {
        runInAction(() => {
          this.aiPlanFailed = true;
        });
      } else {
        if ((error as any)?.response?.data?.errors?.length > 0) {
          Swal.fire({
            text: (error as any)?.response.data.errors[0].message,
            showCancelButton: false,
            confirmButtonText: `Ok`,
          });
        } else {
          toast.error(
            'Could not summarize survey results. Please try again later.',
          );
        }
      }
    }

    runInAction(() => {
      this.loadingAiResponse = false;
    });
  }

  setLoadingStatistics = (loadingStatistics) => {
    this.loadingStatistics = loadingStatistics;
  };

  getStreamedEventKeys = async () => {
    if (!this.currentProject?.id) {
      return;
    }

    try {
      const response = await getStreamedEventKeys(this.currentProject?.id);
      if (response.status === 200) {
        runInAction(() => {
          this.streamedEventKeys = response.data.map((item) => {
            if (item.value === 'sessionStarted') {
              item.label = 'Session started';
            }
            if (item.value === 'pageView') {
              item.label = 'Page view';
            }
            return item;
          });
        });
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  calculateNewLexorank = (ticketId, currentLane, position) => {
    var laneData = JSON.parse(
      JSON.stringify(this.currentTicketsData[currentLane].data),
    );
    laneData = laneData.filter((item) => item.id !== ticketId);
    laneData.splice(position, 0, { name: 'FAKE', lexorank: null });

    let lexorankPrev = LexoRank.min();
    const itemPrev = this.getItemAtPosition(laneData, position - 1);

    if (itemPrev && itemPrev.lexorank) {
      try {
        lexorankPrev = LexoRank.parse(itemPrev.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    let lexorankNext = lexorankPrev.genNext().genNext();
    const itemNext = this.getItemAtPosition(laneData, position + 1);

    if (itemNext && itemNext.lexorank) {
      try {
        lexorankNext = LexoRank.parse(itemNext.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    try {
      return lexorankPrev.between(lexorankNext);
    } catch {
      return LexoRank.middle();
    }
  };

  moveBugInProject = (bugId, status, lexorank, didChangeLane) => {
    var dataToUpdate: any = { status };
    if (this.currentTicketDataSort?.sortKey === 'lexorank' || didChangeLane) {
      dataToUpdate = { status, lexorank };
    }

    this.stores.bugStore.updateBug(bugId, dataToUpdate);
  };

  downloadBugsAsJSON = async (args: {
    feedbackType;
    startDate;
    endDate;
    projection?;
  }) => {
    const { feedbackType, startDate, endDate, projection } = args;

    if (!this.currentProject?.id) {
      return;
    }

    try {
      const exportData = await getExportBoard({
        projectId: this.currentProject?.id,
        type: feedbackType.type,
        startDate,
        endDate,
        projection,
      });
      const json = JSON.stringify(exportData.data);
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = href;
      link.download = `${this.currentProject?.id}_${feedbackType.name}.json`;

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(href); // Clean up the URL
      document.body.removeChild(link);
    } catch (error) {
      // Handle any errors that occur during the download process
      toast.error(
        'Could not download ' + feedbackType.name.toLowerCase() + ' board',
      );
      console.log(error);
    }
  };

  downloadTicketsAsCSV = async (args: {
    feedbackType;
    startDate;
    endDate;
    projection?;
  }) => {
    const { feedbackType, startDate, endDate, projection } = args;

    if (!this.currentProject?.id) {
      return;
    }

    try {
      const exportData = await getCSVExportBoard({
        projectId: this.currentProject?.id,
        type: feedbackType.type,
        startDate,
        endDate,
        projection,
      });
      // Convert the CSV string to a Blob
      const blob = new Blob([exportData.data], {
        type: 'text/csv;charset=utf-8;',
      });
      // Create a URL for the Blob
      const href = URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `${this.currentProject?.id}_${feedbackType.name}.csv`,
      );
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the Blob URL and removing the anchor element
      URL.revokeObjectURL(href);
      document.body.removeChild(link);
    } catch (error) {
      // Handle any errors that occur during the download process
      toast.error(
        'Could not download ' + feedbackType.name.toLowerCase() + ' board',
      );
      console.log(error);
    }
  };

  getProjectUsers = async () => {
    if (!this.currentProject?.id) {
      return;
    }

    try {
      this.getProjectTeams();

      const response = await getProjectUsers(this.currentProject!.id);

      runInAction(() => {
        if (response.status === 200) {
          this.currentProjectUsers = response.data;

          // Update admin state.
          let isAdmin = false;
          let canLeaveProject = false;
          for (let i = 0; i < this.currentProjectUsers.length; i++) {
            if (
              this.currentProjectUsers[i].id ===
                this.stores.usersStore.currentUser.id &&
              this.currentProjectUsers[i].role === 'ADMIN'
            ) {
              isAdmin = true;
            }

            if (
              this.currentProjectUsers[i].id ===
                this.stores.usersStore.currentUser.id &&
              this.currentProjectUsers[i].type === 'project'
            ) {
              canLeaveProject = true;
            }
          }

          this.isProjectAdmin = isAdmin;
          this.canLeaveProject = canLeaveProject;
        }
      });

      // eslint-disable-next-line no-empty
    } catch (exp) {}

    runInAction(() => {
      this.isProjectAdminLoaded = true;
    });
  };

  getProjectTeams = async () => {
    if (!this.currentProject?.id) {
      return;
    }

    try {
      const teams = await HttpTeamService.getInstance().find({});

      runInAction(() => {
        this.currentProjectTeams = teams ?? [];

        // Update unread status.
        if (this?.currentProject?.id) {
          this.getProjectUnreadStatus(this.currentProject.id);
        }
      });
      // eslint-disable-next-line no-empty
    } catch (exp) {}
  };

  getProjectsUnreadCount = async () => {
    try {
      const response = await getProjectsUnreadCount();
      if (response.status === 200) {
        runInAction(() => {
          this.projectsUnreadCount = response.data;
          this.loadingFailed = false;
        });
      } else {
        runInAction(() => {
          this.loadingFailed = true;
        });
      }
    } catch (exp) {
      runInAction(() => {
        this.loadingFailed = true;
      });
    }
  };

  getProjects = async () => {
    this.loadingProjects = true;
    try {
      const response = await getProjects();

      if (response.status === 200) {
        runInAction(() => {
          this.projects = response.data;

          // Subscribe to project updates on app.
          for (let i = 0; i < this.projects.length; i++) {
            const project = this.projects[i];
            if (project) {
              (window as any).messageHandler?.postMessage(
                JSON.stringify({
                  action: PostMessageAction.SUBSCRIBE_TO_TOPIC,
                  data: {
                    topic: `${FCM_TOPIC.PROJECT}-${project.id}`,
                  },
                } as PostMessageData),
              );
            }
          }

          this.initialLoading = false;
        });
      }
    } catch (exp) {
      runInAction(() => {
        this.initialLoading = false;
      });
    }

    runInAction(() => {
      this.loadingProjects = false;
    });
  };

  loadFlowConfig = () => {
    if (this.currentProject && this.currentProject.flowConfig) {
      this.flowConfig = {
        ...this.currentProject.flowConfig,
      };
    }
  };

  setLoadingCurrentProject = (loadingCurrentProject) => {
    this.loadingCurrentProject = loadingCurrentProject;
  };

  getProject = async (id: string) => {
    this.setCurrentProject(undefined);
    this.setLoadingCurrentProject(true);
    const response = await getProject(id);
    if (response && response.status === 200) {
      const project = response.data as Project;
      this.setLoadingCurrentProject(false);
      this.setCurrentProject(project);
      this.getProjectUsers();
      this.getProjectUnreadStatus(project.id);
      this.fetchAndSetProjectActions();
      this.loadFlowConfig();
      this.fetchAndSetEmailTemplates();
      this.fetchAndSetTicketViews();
      this.fetchAndSetMessageTemplates();

      this.stores.outboundStore.getProjectSurveyUnreadStatus(id);
      this.stores.propertyStore.fetchAndSetCurrentProjectProperties();
    }
  };

  resendIntegrations = async () => {
    const response = await resendIntegrations(this.currentProject!.id);
    if (response && response.status === 200) {
      toast.success('Bugs are being sent to your connected integrations. 🎉');
    } else {
      toast.error(
        '🙀 Something went wrong while sending your bugs. Please contact us!',
      );
    }
  };

  loadTeamAvatars = async () => {
    if (!this.currentProject?.apiKey) {
      return;
    }

    loadTeamPreview(this.currentProject.apiKey)
      .then((res) => {
        runInAction(() => {
          this.teamPreview = res.data;
        });
      })
      .catch(() => {});
  };

  loadProjectById = async (
    projectId: string,
    refresh: boolean = false,
    loadTeamAvatars = false,
  ) => {
    if (this.currentProject?.id !== projectId || refresh) {
      await this.getProject(projectId);
      await this.refreshBugsForCurrentProject();
    }

    if (loadTeamAvatars && this.currentProject?.apiKey) {
      this.loadTeamAvatars();
    }
  };

  openFeedbackItem = async ({ shareToken, openModal = true }) => {
    if (!this.currentProject || !shareToken) {
      return;
    }

    const currentPath = window.location.pathname;
    this.stores.bugStore.openFeedbackItem(
      this.currentProject.id,
      shareToken,
      currentPath,
      openModal,
    );
  };

  determineTitle = (dbTitle: string) => {
    if (dbTitle === 'OPEN') {
      return 'Open';
    }
    if (dbTitle === 'INPROGRESS') {
      return 'In Progress';
    }
    if (dbTitle === 'DONE') {
      return 'Done';
    }
    if (dbTitle === 'TOTEST') {
      return 'ToTest';
    }
    return '';
  };

  refreshBugsForCurrentProject = async () => {
    if (this.currentProject) {
      this.archivedBugsDataList.data = [];
      this.prepareCurrentTicketsData({ forceFetch: true });
    }
  };

  clear = () => {
    this.setLoadingCurrentProject(false);
    this.currentTicketsData = {};
    this.ticketViews = [];
    this.currentProjectTeams = [];
    this.currentProjectUsers = [];
    this.currentAnswerStats = {};
    this.bots = [];
    this.bot = undefined;
    this.teamPreview = [];
    this.currentQAAnswers = [];
    this.loadingQAAnswers = false;
    this.loadingBots = false;
    this.loadingCrawlerTasks = false;
    this.loadingCrawlerPages = false;
    this.crawlerTasks = [];
    this.crawlerPages = [];
    this.loadingFailed = false;
    this.isProjectAdmin = false;
    this.canLeaveProject = false;
    this.isProjectAdminLoaded = false;
    this.loadingStatistics = false;
    this.loadingStats = false;
    this.loadingAiResponse = false;
    this.aiResponse = undefined;
    this.aiPlanFailed = false;
    this.streamedEventKeys = [];
    this.localUnreadStatus = {};
    this.unreadStatus = {};
    notificationTicketCache.clear();
  };

  clearCurrentProject = () => {
    this.setCurrentProject(undefined);
  };

  createProject = async (
    name: string,
    description: string,
    picture: string,
    templateId?: string,
    hideToast?: boolean,
  ) => {
    if (!this.stores.organisationStore.currentOrganisation) {
      return false;
    }

    this.setCurrentProject(undefined);
    this.currentTicketsData = {};
    this.setLoadingCurrentProject(true);
    notificationTicketCache.clear();

    try {
      const response = await createProject(
        name,
        description,
        picture,
        this.stores.organisationStore.currentOrganisation.id,
        templateId,
      );
      if (response.status === 201) {
        this.setCurrentProject(response.data as Project);
        this.setLoadingCurrentProject(false);
        this.getProjectUsers();
        this.loadFlowConfig();
        this.getProjects();
        this.fetchAndSetEmailTemplates();
        this.refreshBugsForCurrentProject();
        this.fetchAndSetProjectActions();

        trackEvent('add.project', {
          organization_id: response.data?.organisation?.id,
          project_id: response.data?.id,
        });

        return true;
      }
    } catch (error: any) {
      if (error.response.status === 408) {
        this.stores.modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
          type: 'projectlimit',
        });
      } else {
        toast.error('Could not create project.');
      }
    }
    return false;
  };

  addProjectType = async (boardName: string, baseName: string) => {
    if (
      !boardName ||
      !baseName ||
      boardName.length === 0 ||
      baseName.length === 0
    ) {
      return;
    }

    if (!this.currentProject?.id) {
      return;
    }

    const response = await addProjectType(
      this.currentProject?.id,
      boardName,
      baseName,
    );
    if (response.status === 201) {
      // Hard refresh.
      this.getProject(this.currentProject?.id);
    } else {
      toast.error('Could not create board.');
    }
  };

  updateProject = async (
    id: string,
    data: any,
    showToast = true,
    reloadAfterUpdate = true,
    optimistic = false,
  ) => {
    if (optimistic) {
      runInAction(() => {
        this.currentProject = {
          ...this.currentProject!,
          ...data,
        };
      });
    }

    const response = await updateProject(id, data);
    if (response.status === 200) {
      if (showToast) {
        toast.success('Project updated 🎉');
      }
      if (reloadAfterUpdate) {
        this.setCurrentProject(response.data as Project);
        this.getProjectUsers();
        this.fetchAndSetEmailTemplates();
        this.loadFlowConfig();
        this.fetchAndSetProjectActions();
        this.getProjects();
      } else {
        runInAction(() => {
          this.currentProject = {
            ...this.currentProject!,
            ...response.data,
          };
        });
      }
    }
  };

  updateProjectImage = async (id: string, picture: string) => {
    const response = await updateProjectPicture(id, picture);
    if (response.status === 200) {
      this.setCurrentProject(response.data as Project);
      this.getProjectUsers();
      this.fetchAndSetEmailTemplates();
      this.loadFlowConfig();
      this.getProjects();
    }
  };

  leaveProject = async (projectID: string) => {
    try {
      await leaveProject(projectID);

      runInAction(() => {
        // Reload projects
        this.projects = [];
        this.setCurrentProject(undefined);
        this.getProjects();

        // Redirect to dashboard
        this.stores.navigate('/dashboard');
      });
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error('You are not authorizied');
      } else {
        toast.error('Could not leave project. Please try it again later!');
      }
    }
  };

  deleteProject = async (projectID: string) => {
    try {
      await deleteProject(projectID);

      // Reload projects
      this.getProjects();

      // Redirect to dashboard
      this.stores.navigate('/dashboard');

      // Cleanup current project.
      this.setCurrentProject(undefined);
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error('You are not authorizied');
      } else {
        toast.error('Could not delete project. Please try it again later!');
      }
    }
  };

  deleteCompletedBugs = async (
    projectID: string,
    status: string,
    type: string,
  ) => {
    try {
      await deleteCompletedBugs(projectID, status, type);
      toast.success('Successfully deleted items in column');
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error('You are not authorizied');
      } else {
        toast.error(
          'Could not delete completed bugs. Please try it again later!',
        );
      }
    }
  };

  archiveFeedbackItems = async (status, type) => {
    if (!this.currentProject) {
      return;
    }

    try {
      runInAction(() => {
        this.currentTicketsData[status].data = [];
        this.currentTicketsData[status].count = 0;
      });

      await archiveFeedbackItems(this.currentProject.id, status, type);

      toast.success('Successfully archived items in column');
    } catch (err: any) {
      toast.error('Could not archive items in column');
    }
  };

  setLoadingArchivedBugs = (loadingArchivedBugs: boolean) => {
    this.loadingArchivedBugs = loadingArchivedBugs;
  };

  updateFlowConfig = async (updatedFlowConfig: Record<string, any> = {}) => {
    if (!updatedFlowConfig) {
      return;
    }

    // Update local flowConfig
    this.flowConfig = {
      ...toJS(this.flowConfig),
      ...updatedFlowConfig,
    };

    // Update only the modified keys on the server
    await this.updateProject(
      this.currentProject!.id,
      {
        flowConfig: updatedFlowConfig, // Send only the modified keys
        showOnboarding: false,
      },
      false,
      false,
    );
  };

  getArchivedFeedbackItems = async (args: {
    isSpam?: boolean;
    loadMore?: boolean;
    type?: string;
  }) => {
    if (this.archivedBugsDataList.isLoading || !this.currentProject) {
      return;
    }

    try {
      this.archivedBugsDataList.isLoading = true;

      if (args.loadMore) {
        this.archivedBugsDataList.pageIndex += 1;
      } else {
        this.archivedBugsDataList.pageIndex = 0;
        this.archivedBugsDataList.data = [];
        this.archivedBugsDataList.totalItems = 0;
      }

      const response = await getArchivedBugs(
        this.currentProject.id,
        args.isSpam ?? false,
        args.type ?? '',
        getSkipAndLimitFromPage({
          pageIndex: this.archivedBugsDataList.pageIndex,
          itemsInPage,
        }),
      );

      if (response.status === 200) {
        runInAction(() => {
          this.archivedBugsDataList.totalItems = response.data?.count ?? 0;
          this.archivedBugsDataList.data = [
            ...this.archivedBugsDataList.data,
            ...response.data.bugs,
          ];
        });
      }

      this.archivedBugsDataList.isLoading = false;
    } catch (_) {
      this.archivedBugsDataList.isLoading = false;
    }
  };

  localyRemoveArchivedBug = async (id: string) => {
    if (!this.currentProject) {
      return;
    }

    this.archivedBugsDataList.data = this.archivedBugsDataList.data.filter(
      (archivedBug) => archivedBug.id !== id,
    );
  };

  handleTags = (tags: string[]) => {
    if (!this.currentProject) {
      return;
    }

    const feedbackTags = this.currentProject?.feedbackTags ?? [];
    for (let i = 0; i < tags.length; i++) {
      const currentTag = tags[i];
      if (
        feedbackTags.filter((tagItem) => tagItem.label === currentTag)
          .length === 0
      ) {
        feedbackTags.push({ label: currentTag, color: getRandomColor() });
        this.currentProject!.feedbackTags = feedbackTags;
        this.updateProject(this.currentProject!.id, this.currentProject, false);
      }
    }
  };

  getFeatureRequestStates = (excludeOpenDone = true) => {
    const featureRequestStates = this.currentProject?.projectTypes
      .find((value) => value.type === 'FEATURE_REQUEST')
      ?.options.possibleLanes.filter((featureRequestState) => {
        if (
          excludeOpenDone &&
          (featureRequestState.key === 'OPEN' ||
            featureRequestState.key === 'CLOSED' ||
            featureRequestState.key === 'DONE')
        ) {
          return false;
        }
        return true;
      })
      .map((item) => {
        let color;
        switch (item.key) {
          case 'OPEN':
            color = '#85B5B3';
            break;

          case 'PLANNED':
            color = '#1FA0FF';
            break;

          case 'INPROGRESS':
            color = '#9B59B6';
            break;

          case 'DONE':
            color = '#30CB83';
            break;

          default:
            color = hashToColor(item.key);
            break;
        }

        return { ...item, color: color };
      });

    return featureRequestStates;
  };

  setEditingProject = () => {
    if (!this.currentProject) {
      return;
    }

    this.editingProject = JSON.parse(JSON.stringify(this.currentProject));

    this.editingProject!.flowConfig = JSON.parse(
      JSON.stringify(this.flowConfig),
    );
  };

  saveEditingProject = async (
    updatedKeys: Record<string, any>,
    showToast: boolean = true,
  ) => {
    if (!this.editingProject || !this.currentProject) {
      return;
    }

    // Update specific keys in editingProject
    Object.assign(this.editingProject, updatedKeys);

    await this.updateFlowConfig(updatedKeys.flowConfig);

    runInAction(() => {
      this.setEditingProject();
    });

    if (showToast) {
      toast.success('Saved 🎉');
    }
  };

  /**
   * Sets the verify domain flag
   * @param isVerifyingDomain
   */
  setIsVerifyingDomain = (isVerifyingDomain) => {
    this.isVerifyingDomain = isVerifyingDomain;
  };

  /**
   * Add a domain to an organisation
   * @param domainName
   * @returns
   */
  addDomain = async (domainName: string) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    this.setIsVerifyingDomain(true);
    try {
      const resp = await addDomain(this.currentProject?.id, domainName);
      if (resp) {
        this.setCurrentProject(resp.data as Project);
        this.setIsVerifyingDomain(false);
        toast.success('Domain added 🎉');
        return;
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    toast.error('Could not add domain. Please contact our support team!');
    this.setIsVerifyingDomain(false);
  };

  verifyDomain = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    this.setIsVerifyingDomain(true);
    const resp = await verifyDomain(this.currentProject?.id);
    if (resp) {
      this.setCurrentProject(resp.data as Project);
    } else {
      toast.error('Verification failed.');
    }

    this.setIsVerifyingDomain(false);
  };

  removeDomain = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
    }

    const resp = await removeDomain(this.currentProject?.id);
    if (resp) {
      this.setCurrentProject(resp.data as Project);
      return resp.data as Project;
    } else {
      toast.error('Domain removal failed.');
    }

    return null;
  };

  setIsUpdatingSender = (isUpdatingSender) => {
    this.isUpdatingSender = isUpdatingSender;
  };

  updateRoadmapSettings = async (data: any) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    await this.updateProject(
      this.currentProject!.id,
      {
        roadmapSettings: data,
      },
      false,
      false,
    )
      .then(() => toast.success('Sender settings updated 🎉'))
      .catch(() => toast.error('An error occurred.'));
  };

  updateDomainSettings = async (senderType, senderName, senderEmail) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    this.setIsUpdatingSender(true);
    const resp = await updateDomainSettings(
      this.currentProject?.id,
      senderType,
      senderName,
      senderEmail,
    );
    if (resp) {
      this.setCurrentProject(resp.data as Project);
      toast.success('Sender settings updated 🎉');
    } else {
      toast.error('Settings update failed.');
    }

    this.setIsUpdatingSender(false);
  };

  updateCustomDomainSettings = async (customDomain) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await updateCustomDomainSettings(
      this.currentProject?.id,
      customDomain,
    );
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomain = customDomain;
      });
    } else {
      toast.error('Domain is already in use by a Gleap customer.');
    }
  };

  deleteCustomDomainSettings = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await deleteCustomDomainSettings(this.currentProject?.id);
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomain = undefined;
      });
    }
  };

  updateCustomDomainHelpCenterSettings = async (customDomain) => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await updateCustomDomainHelpCenterSettings(
      this.currentProject?.id,
      customDomain,
    );
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomainHelpCenter = customDomain;
      });
    } else {
      toast.error('Domain is already in use by a Gleap customer.');
    }
  };

  clearHelpCenterCache = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await clearHelpCenterCache(this.currentProject?.id);
    if (resp && resp.data && resp.data.success) {
      toast.success('Cache cleared ✅');
    } else {
      toast.error('An error occurred.');
    }
  };

  deleteCustomDomainHelpCenterSettings = async () => {
    if (!this.currentProject?.id) {
      toast.error('An error occurred.');
      return;
    }

    const resp = await deleteCustomDomainHelpCenterSettings(
      this.currentProject?.id,
    );
    if (resp && resp.data && resp.data.success) {
      runInAction(() => {
        this.currentProject!.customDomainHelpCenter = undefined;
      });
    }
  };

  migrateIntegrations = async () => {
    migrateIntegrations(this.currentProject?.id);
  };

  globalSearch = async (searchTerm: string) => {
    try {
      this.searchGlobalForTickets(searchTerm);
      this.searchGlobalForSessions(searchTerm);
      this.searchGlobalForComments(searchTerm);
      this.searchGlobalForOutbounds(searchTerm);
      this.searchGlobalForHelpcenterArticles(searchTerm);
    } catch (err) {}
  };

  searchGlobalForTickets = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.bug = {
        label: 'Tickets',
        type: 'TICKET',
        sort: 1,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForFeedbackItems({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.bug = {
          label: 'Tickets',
          type: 'TICKET',
          sort: 1,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchGlobalForSessions = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.session = {
        label: 'Contacts',
        type: 'SESSION',
        sort: 2,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForSessions({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.session = {
          label: 'Contacts',
          type: 'SESSION',
          sort: 2,
          isLoading: false,
          data: response.data,
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchGlobalForComments = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.comment = {
        label: 'Comments',
        type: 'COMMENT',
        sort: 3,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForComments({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.comment = {
          label: 'Comments',
          type: 'COMMENT',
          sort: 3,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchGlobalForOutbounds = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.outbound = {
        label: 'Outbounds',
        type: 'OUTBOUND',
        sort: 4,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForOutbounds({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });

      runInAction(() => {
        this.globalSearchData.outbound = {
          label: 'Outbounds',
          type: 'OUTBOUND',
          sort: 4,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  sumStatusCounts = (statusCounts, specificLane?: string) => {
    let count = 0;

    if (statusCounts) {
      for (const item of statusCounts) {
        if (specificLane) {
          if (item.status === specificLane) {
            count += item.count;
            continue;
          }
          continue;
        }
        // if (
        //   this.currentFeedbackType?.type === 'INQUIRY' &&
        //   item.status !== this.inboxTicketStatusSelection
        // ) {
        //   continue;
        // }
        count += item.count;
      }
    }

    return count;
  };

  searchGlobalForHelpcenterArticles = async (searchTerm: string) => {
    if (!this.currentProject) {
      return;
    }

    runInAction(() => {
      this.globalSearchData.helpcenterArticle = {
        label: 'Articles',
        type: 'HELPCENTER_ARTICLE',
        sort: 5,
        isLoading: true,
        data: [],
      };
    });

    try {
      const response = await searchForHelpcenterArticles({
        projectId: this.currentProject.id,
        query: {
          searchTerm,
        },
      });
      runInAction(() => {
        this.globalSearchData.helpcenterArticle = {
          label: 'Articles',
          type: 'HELPCENTER_ARTICLE',
          sort: 5,
          isLoading: false,
          data: response.data && response.data.hits ? response.data.hits : [],
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  fetchAndSetProjectActions = async () => {
    if (!this.currentProject) {
      return;
    }

    try {
      const actions = await HttpProjectActionService.getInstance().find({});
      if (actions) {
        runInAction(() => {
          this.projectActions = actions;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getProjectActionByActionId = (actionId: string) => {
    return this.projectActions.find((action) => action.actionId === actionId);
  };

  fetchAndSetEmailTemplates = async () => {
    if (!this.currentProject || this.emailTemplatesDataList.isLoading) {
      return;
    }

    try {
      this.emailTemplatesDataList.isLoading = true;

      const emailTemplates =
        await HttpEmailTemplateClientService.getInstance().find({});
      runInAction(() => {
        this.emailTemplatesDataList.data = emailTemplates ?? [];
        this.emailTemplatesDataList.isLoading = false;
      });
    } catch (err) {
      this.emailTemplatesDataList.isLoading = false;
      toast.error('Could not fetch email templates.');
    }
  };

  createEmailTemplate = async (data: EmailTemplate) => {
    if (!this.currentProject) {
      return;
    }

    try {
      const emailTemplate =
        await HttpEmailTemplateClientService.getInstance().create({ data });
      runInAction(() => {
        this.emailTemplatesDataList.data = [
          ...this.emailTemplatesDataList.data,
          emailTemplate,
        ];
      });

      toast.success('Email template created.');
      return emailTemplate;
    } catch (err) {
      toast.error('Could not create email template.');
    }
  };

  updateEmailTemplate = async (id: string, data: EmailTemplate) => {
    if (!this.currentProject) {
      return;
    }

    try {
      const emailTemplate =
        await HttpEmailTemplateClientService.getInstance().updateOne({
          id,
          data,
        });
      runInAction(() => {
        this.emailTemplatesDataList.data = this.emailTemplatesDataList.data.map(
          (item) => {
            if (item._id === id) {
              return emailTemplate;
            }
            return item;
          },
        );
      });
      toast.success('Email template updated.');
    } catch (err) {
      toast.error('Could not update email template.');
    }
  };

  getAndSetCurrentProjectEmailTemplate = async (id: string) => {
    if (!this.currentProject) {
      return;
    }

    try {
      const emailTemplate =
        await HttpEmailTemplateClientService.getInstance().findOne({ id });

      runInAction(() => {
        this.currentEmailTemplate = emailTemplate;
      });
    } catch (err) {
      toast.error('Could not fetch email template.');
    }
  };

  refreshData = () => {
    if (this.currentProject) {
      this.getProjectUnreadStatus(this.currentProject.id);
      this.refreshBugsForCurrentProject();
      this.updateTicketsCountDebounced();
    }
  };

  addActionFlowNode(node) {
    if (!this.bot || !this.bot.actionFlows) {
      return;
    }

    this.bot.actionFlows.push(node);
    this.bot.actionFlows = [...this.bot.actionFlows];
  }

  loadAIFunctions = async () => {
    if (!this.currentProject) {
      return;
    }

    this.aiFunctions = [];

    const response = await getAIFunctions(this.currentProject.id);
    if (response.status === 200) {
      runInAction(() => {
        this.aiFunctions = response?.data?.kaiFunctions ?? [];
      });
    }
  };

  createAIFunction = async (data) => {
    if (!this.currentProject) {
      return false;
    }

    const response = await createAIFunction(this.currentProject.id, data);
    if (response.status === 201) {
      runInAction(() => {
        this.aiFunctions = [...this.aiFunctions, response.data];
      });

      return true;
    }

    return false;
  };

  deleteAIFunction = async (id) => {
    if (!this.currentProject) {
      return;
    }

    const response = await deleteAIFunction(this.currentProject.id, id);
    if (response.status === 200) {
      runInAction(() => {
        this.aiFunctions = this.aiFunctions.filter((item) => item.id !== id);
      });
    }
  };

  updateAIFunction = async (id, data) => {
    if (!this.currentProject) {
      return;
    }

    const response = await updateAIFunction(this.currentProject.id, id, data);
    if (response.status === 200) {
      runInAction(() => {
        const index = this.aiFunctions.findIndex((item) => item.id === id);
        if (index !== -1) {
          try {
            this.aiFunctions[index] = response.data;
          } catch (exp) {}

          this.aiFunctions = [...this.aiFunctions];
        }
      });
    }
  };

  searchSmartLinks = async (searchTerm: string, lang: string) => {
    const response = await searchSmartLinks({
      projectId: this.currentProject!.id,
      query: {
        searchTerm,
        includeSDKLinks: true,
        lang: lang ?? 'en',
        articleAsUrl: false,
      },
    });

    return response?.data ?? [];
  };

  getAgentReport = async (userId: string, nextPage?: boolean) => {
    if (!this.currentProject) {
      return;
    }

    if (nextPage) {
      this.agentReport.page += 1;
    }

    this.agentReport = {
      ...this.agentReport,
      hasMore: true,
      isLoading: true,
    };

    const response = await fetchAgentReport({
      projectId: this.currentProject.id,
      query: {
        processingUser: userId,
        ...getSkipAndLimitFromPage({
          pageIndex: this.agentReport.page,
          itemsInPage: 25,
        }),
      },
    });

    if (response.status === 200) {
      if (response?.data?.tickets?.length < 25) {
        this.agentReport.hasMore = false;
      }

      const data = nextPage
        ? [...this.agentReport.data, ...response.data.tickets]
        : response.data.tickets;

      this.agentReport = {
        ...this.agentReport,
        isLoading: false,
        data: data,
        user: response.data.user,
      };
    } else {
      this.agentReport = {
        ...this.agentReport,
        isLoading: false,
      };
    }
    return null;
  };

  createContactView = async (data) => {
    if (!this.currentProject) {
      return;
    }

    const response = await HttpContactViewService.getInstance().create({
      data,
    });
    if (response.status === 201) {
      return response.data;
    }

    return null;
  };

  updateContactView = async (id, data) => {
    if (!this.currentProject) {
      return;
    }

    const response = await HttpContactViewService.getInstance().updateOne({
      id,
      data,
    });
    if (response.status === 200) {
      return response.data;
    }

    return null;
  };

  findAllContactViews = async () => {
    if (!this.currentProject) {
      return;
    }

    const response: any = await HttpContactViewService.getInstance().find({});

    if (response && response.length > 0) {
      this.contactViews = response;
      return response;
    }

    return null;
  };

  deleteContactView = async (id) => {
    if (!this.currentProject || !id) {
      return;
    }

    const response = await HttpContactViewService.getInstance().deleteOne({
      id,
    });

    if (response && response.id) {
      this.contactViews = this.contactViews.filter((item) => item._id !== id);
    }
  };

  fetchAndSetTicketViews = async () => {
    if (!this.currentProject) {
      return;
    }

    const response = await HttpTicketViewService.getInstance().find({});
    if (response && response.length > 0) {
      runInAction(() => {
        this.ticketViews = response;
        this.lastUpdatedUnreadStatus = null;
        this.getProjectUnreadStatus(this?.currentProject?.id);
      });
    }
  };

  fetchAndSetMessageTemplates = async () => {
    if (!this.currentProject) {
      return;
    }

    const response = await HttpMessageTemplateService.getInstance().find({});
    if (response && response.length > 0) {
      runInAction(() => {
        this.messageTemplates = response;
      });
    }
  };

  getTicketViewsForFeedbackType = (feedbackType: string) => {
    if (!this.ticketViews) {
      return null;
    }

    return this.ticketViews.filter((item) =>
      item.dataSources.includes(feedbackType),
    );
  };

  prepareViewFilter = (view: TicketView) => {
    let filter = {};
    const conditionKeys = Object.keys(view.conditions);

    for (let i = 0; i < conditionKeys.length; i++) {
      const key = conditionKeys[i];
      const condition = view.conditions[key];

      if (
        typeof condition !== 'object' ||
        condition === null ||
        Array.isArray(condition)
      ) {
        filter[key] = condition;
        continue;
      }

      let operator: any = null;
      let regex: any = null;
      let query: any = null;

      switch (condition.operator) {
        case 'is':
          operator = '$eq';
          break;
        case 'isnot':
          operator = '$ne';
          break;
        case 'greaterthan':
          operator = '$gt';
          break;
        case 'lessthan':
          operator = '$lt';
          break;
        case 'contains':
          regex = { $regex: condition.value, $options: 'i' };
          break;
        case 'notcontains':
          regex = {
            $regex: '^((?!' + condition.value + ').)*$',
            $options: 'i',
          };
          break;
        case 'startswith':
          regex = { $regex: '^' + condition.value, $options: 'i' };
          break;
        case 'endswith':
          regex = { $regex: condition.value + '$', $options: 'i' };
          break;
        case 'empty':
          query = { $in: [null, ''] };
          break;
        case 'notempty':
          query = { $nin: [null, ''] };
          break;
      }

      if (regex) {
        filter[key] = regex;
      } else if (query) {
        filter[key] = query;
      } else if (operator) {
        filter[key] = {
          [operator]: condition.data,
        };
      }
    }

    return filter;
  };

  setCurrentSelectedView = (view: TicketView) => {
    this.currentTicketDataFilter = this.prepareViewFilter(view);
    this.currentTicketDataSort = view.sortBy;
    this.currentSelectedView = view._id;

    this.setViewType(view.viewType.toUpperCase());
    this.filterTicketsDataInternal(view._id);
  };

  resetCurrentSelectedView = (filters = {}, sort = {}) => {
    this.currentTicketDataFilter = filters;
    this.currentTicketDataSort = sort;
    this.currentSelectedView = 'ALL';

    this.setViewType('BOARD');
    this.filterTicketsDataInternal();
  };
}
